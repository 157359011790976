export default {
  "language": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglese"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedesco"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francese"])},
    "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungherese"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croato"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polacco"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sloveno"])}
  },
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
  "load": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricare"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartella progetto - Selezionare un progetto da caricare"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividere il progetto"])},
    "shareText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Per accedere a questo progetto, copiate e incollate il link sottostante per condividerlo con colleghi o clienti:\n\n", _interpolate(_named("link")), "\n\nAltri soggetti dovranno registrarsi per accedere alla stessa versione del software di progettazione idraulica."])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia link"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare il progetto"])},
    "noProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun progetto salvato"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra da ", "{", "first", "}", " a ", "{", "last", "}", " dei progetti ", "{", "totalRecords", "}"])},
    "progressBar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Caricato 0 linee di ", _interpolate(_named("total"))]), _normalize(["Caricato 1 linea di ", _interpolate(_named("total"))]), _normalize(["Caricato ", _interpolate(_named("count")), " linee di ", _interpolate(_named("total"))])])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare il progetto"])}
  },
  "save": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva progetto"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi progetto con HAURATON"])},
    "advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON può essere in grado di fornire preziosi input/consigli durante la verifica del progetto e/o può aiutare a ottenere le configurazioni dei canali più convenienti."])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se è necessaria una risposta urgente dopo la condivisione di un progetto, si prega di contattare HAURATON:"])},
    "calculateRuns": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le linee di seguito richiedono un calcolo prima di essere salvate: ", _interpolate(_named("runs"))])},
    "needProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il progetto ha bisogno di un nome"])},
    "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessione scaduta, provare ad accedere con un'altra scheda/finestra e poi salvare nuovamente"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile salvare il progetto…"])}
  },
  "generic_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*L'immagine della sezione trasversale è generica e utilizzata solo a scopo illustrativo"])},
  "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del progetto"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progetto"])},
  "projectDetailsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione del progetto"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcoli"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcola"])},
  "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessione scaduta, provare ad accedere con un'altra scheda/finestra, quindi calcolare nuovamente"])},
  "addRunLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi linea"])},
  "addRunLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una nuova linea di canale al progetto corrente"])},
  "addNotesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi note"])},
  "addNotesLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere note alla linea del canale in fase di progettazione. Le note saranno riportate nelle schede tecniche idrauliche."])},
  "resetLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetta"])},
  "resetLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utente può riportare il canale in fase di progettazione e la finestra Parametri di progettazione alle impostazioni predefinite."])},
  "duplicateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplica"])},
  "duplicateLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una copia della linea di canale attuale"])},
  "catchmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
  "channelsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canali"])},
  "resultsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultati"])},
  "designRunsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progettazione"])},
  "materialsListLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei materiali"])},
  "documentsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti"])},
  "selectionOfDrainageSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema di drenaggio"])},
  "selectionOfChannelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione canale"])},
  "confirmedLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza confermata"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N."])},
  "freeOutflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarico libero"])},
  "outflowInTrashbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarico tramite pozzetto"])},
  "trashboxes": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegliere il pozzetto"])},
    "chooseShared": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scegliere il pozzetto (condiviso con ", _interpolate(_named("shared")), ")"])},
    "withDischarge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pozzetto con ", _interpolate(_named("discharge")), "mm scairco"])},
    "shareWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Condiviso con ", _interpolate(_named("run"))])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Non sono disponibili pozzetti ", _interpolate(_named("loading")), "+ per i canali di ", _interpolate(_named("type"))])}
  },
  "modificationLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica elemento finale di scarico"])},
  "noBuildInFall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escludi i canali con pendenza"])},
  "definitionOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista dell'ultimo elemento forato"])},
  "drillingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foratura sull'elemento di scarico"])},
  "diameterOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diametro del foro"])},
  "diameterOfDrillingTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diametro del foro da realizzare nel canale"])},
  "distanceEdgeToDrillingCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza: dalla fine del canale al centro del foro"])},
  "bottomAxial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parete inferiore assiale"])},
  "leftSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parete laterale sinistra"])},
  "rightSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parete laterale destra"])},
  "cuttingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taglio sull'elemento di scarico"])},
  "lengthFromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza dalla fine"])},
  "angleOfCutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angolo di taglio"])},
  "designParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri di progetto"])},
  "channelRunLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo"])},
  "channelRunLocationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione del canale/drenaggio puntuale in fase di progettazione"])},
  "channelLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza"])},
  "channelLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione della lunghezza della linea del canale. La lunghezza della linea deve essere espressa in multipli di 1m. Non comprende le dimensioni del pozzetto all'inizio e alla fine del percorso del canale."])},
  "catchmentDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondità area"])},
  "catchmentDepthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza del bacino di raccolta si estende da ciascun lato lungo la linea del canale"])},
  "catchmentArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
  "catchmentAreaTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area totale (m²) che la linea del canale deve drenare."])},
  "catchment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area irregolare"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'Uniforme' - Un bacino idrografico di forma rettangolare con la stessa permeabilità.", _interpolate(_list(0)), "'Irregolare' - Un bacino idrografico composto potenzialmente da più trapezi, con permeabilità indipendenti.", _interpolate(_list(0)), "Fare riferimento a Progettazione di bacini idrografici irregolari nel file HELP."])},
    "irregular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irregolare"])},
    "uniform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniforme"])},
    "editShape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit area"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri"])}
  },
  "catchmentWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superamento dell'area massima"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'area deve essere inferiore a 100.000 m², si prega di ridurre il progetto di almeno ", _interpolate(_named("excess")), "m²"])}
  },
  "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione"])},
  "configurationTT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A gradini' - Canale con diverse altezze che vanno verso lo scarico.", _interpolate(_list(0)), "'Singolo' - Canali della stessa larghezza e altezza interconnessa per formare la linea.", _interpolate(_list(0)), "Per ulteriori informazioni, consultare il file HELP."])},
  "stepped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gradini"])},
  "stepWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I progetti con i canali in cemento non possono essre a gradini"])},
    "steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I progetti con i canali in acciaio non possono essere a gradini"])}
  },
  "rainfall": {
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappa piovosità"])},
    "mapTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliccare sull'icona della goccia di pioggia per la mappa delle precipitazioni nel Regno Unito. Selezionate i dati pluviometrici precisi per località specifiche facendo ulteriore clic sulla Mappa delle precipitazioni del Regno Unito. I valori di intensità delle precipitazioni possono essere inseriti anche manualmente."])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo"])},
    "intensity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intensità di pioggia"])},
    "intensityTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare clic sul testo 'mm/ora' per cambiare la misura delle precipitazioni in 'litri/secondo/ettaro' (l/(s*ha)) o 'litri/secondo/m²' (l/s/m²)."])},
    "stormDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata evento"])},
    "r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r5"])},
    "r15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r15"])},
    "r60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r60"])},
    "default": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta Intensità pioggia predefinite"])},
      "changeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si desidera utilizzare la pioggia selezionata come predefinita per le nuove linee?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    }
  },
  "calculationMethod": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di calcolo"])},
    "gvf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manning"])},
    "hrw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HRW"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["HAURATON DesignSoftware offre all'utente la possibilità di scegliere tra due formule di progettazione idraulica per il calcolo delle dimensioni dei canali:", _interpolate(_list(0)), "Manning-Strickler modificata (Manning)", _interpolate(_list(0)), "Hydraulics Research Wallingford (HRW).", _interpolate(_list(0)), "Per ulteriori informazioni, consultare il file 'Help'."])}
  },
  "slopeSetting": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazione della pendenza"])},
    "useSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendenza"])},
    "useElevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'Pendenza' - Include la pendenza del terreno come valore percentuale.", _interpolate(_list(0)), "'Altezza' - Pendenza del terreno calcolata utilizzando la variazione verticale del livello del terreno all'inizio e alla fine del percorso del canale."])}
  },
  "longitudinalSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendenza longitudinale"])},
  "longitudinalSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si riferisce alla pendenza longitudinale della superficie del terreno lungo la quale viene posato il canale (non alla pendenza del bacino drenante verso il canale). Se il terreno è pianeggiante, inserire 0%."])},
  "longitudinalSlopeWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angolo di pendenza massimo superato"])},
    "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La pendenza massima consentita per la quale è valida la teoria idraulica è di 1 in ", _interpolate(_named("limit"))])}
  },
  "elevationChange": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variazione di quota"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["L'altezza verticale del terreno alle estremità della linea che, insieme alla lunghezza del canale, può essere utilizzata per calcolare la pendenza longitudinale del terreno.", _interpolate(_list(0)), "T = Valore dell'altezza alla testa (inizio) del canale", _interpolate(_list(0)), "S = Valore dell'altezza allo scarico del canale"])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testa"])},
    "discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarico"])}
  },
  "elevationWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superamento del rapporto di variazione massima dell'altezza"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La pendenza massima consentita per la quale è valida la teoria idraulica è di 1 su 30.", _interpolate(_list(0)), "Si prega di ridurre la variazione di quota del progetto o di aumentarne la lunghezza"])}
  },
  "elevationUphillWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La testa del canale è inferiore alla portata"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["DS3.0 è stato progettato per canali installati su terreni pianeggianti o con lievi pendenze longitudinali positive.", _interpolate(_list(0)), "Assicurarsi che la variazione di quota non comporti una pendenza negativa"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio di prospetti"])}
  },
  "superCriticalWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni importanti"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS3.0 è stato progettato ipotizzando una variazione graduale del flusso all'interno dei sistemi di drenaggio lineari installati su terreni pianeggianti o con lievi pendenze longitudinali."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per i canali installati lungo pendii più ripidi, il progettista deve considerare che il flusso d'acqua all'interno del sistema può variare tra condizioni subcritiche e supercritiche. Questo può portare a una maggiore turbolenza all'interno del canale e a possibili allagamenti superficiali localizzati."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per i progetti con pendenze longitudinali più elevate, DS3.0 fornirà progetti di canali con una capacità sufficiente ad accogliere la portata calcolata, ma non tiene conto della rapida variazione del flusso che può verificarsi all'interno del corpo del canale con le conseguenze che ne derivano."])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per queste situazioni speciali, consigliamo ai progettisti di utilizzare la metodologia e la formula appropriate per anticipare, prevedere e tenere conto delle condizioni di flusso supercritiche."])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non mostrare più"])}
  },
  "controlledDischarge": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarico controllato"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarico controllato"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il canale recyfix HICAP  può essere utilizzato per lo stoccaggio e l'attenuazione dell'acqua.", _interpolate(_list(0)), "Il progettista può controllare la velocità di scarico dal canale.", _interpolate(_list(0)), "Il valore viene visualizzato come '0' se il flusso è illimitato.", _interpolate(_list(0)), "Fare riferimento a 'Scarico controllato' nel file HELP."])},
    "stepWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I progetti che utilizzano scarichi controllati non possono essere a gradini o in pendenza"])},
    "free": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarico libero"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità con cui l'acqua esce dal canale se non è limitata, in base all'intensità delle precipitazioni e al bacino idrografico"])}
    },
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "resetTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina lo scarico libero"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcola"])},
    "calculateTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolare il risultato del limite"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica"])},
    "confirmTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzare il flusso di scarico controllato per la linea corrente"])},
    "flooded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile progettare un canale che soddisfi i parametri attuali"])},
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale scelto"])},
    "criticalDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata critica"])},
    "orificeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diametro del foro"])},
    "criticalRainfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piovosità critica"])},
    "totalChannelVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume totale del canale"])},
    "minimumFreeBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pannello libero minimo"])}
  },
  "minimumChannelSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione minima del canale"])},
  "minimumChannelSizeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dimensione minima del canale da utilizzare per la linea attuale"])},
  "lengthWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza massima del canale consigliata superata"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La lunghezza massima del canale consigliata è di 500 m, si prega di considerare di ridurre il progetto di almeno ", _interpolate(_named("excess")), "m"])}
  },
  "lengthError": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superamento della lunghezza massima del canale"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La lunghezza massima del canale per un progetto di ", _interpolate(_named("method")), " è di ", _interpolate(_named("limit")), "m, si prega di ridurre il progetto di almeno ", _interpolate(_named("excess")), "m"])}
  },
  "divide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividi"])},
  "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisci"])},
  "divide&mergeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per informazioni dettagliate sulle aree di drenaggio divise o unite, consultare il file HELP (Progettazione di aree di drenaggio irregolari)"])},
  "complexCatchment": {
    "areaNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero aree"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza"])},
    "startDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza iniziale"])},
    "endDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza finale"])},
    "surfaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
    "permeability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permeabilità"])}
  },
  "areaLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza area"])},
  "leftSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Sinistra"])},
  "rightSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Destra"])},
  "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singola"])},
  "designSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifiche di progettazione"])},
  "selectedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema scelto"])},
  "channelNominalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larghezza interna canale"])},
  "channelInternalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larghezza interna canale"])},
  "channelInternalDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza interna canale"])},
  "channelRoughness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruvidità"])},
  "channelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo canale"])},
  "invertDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza finale"])},
  "overallDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza totale"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica"])},
  "flowVelocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità flusso"])},
  "channelDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarico del canale"])},
  "floodingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentuale in eccesso (circa)"])},
  "dischargeFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portata di scarico"])},
  "usedVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume usato"])},
  "totalSystemVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale volume del sistema"])},
  "freeboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bordo libero"])},
  "installedGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendenza"])},
  "installedGradientTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il canale è progettato con una configurazione 'a gradini', la differenza di altezza tra le unità di canale incluse contribuirà alla 'pendenza installata' del canale. In questi casi la 'pendenza installata' sarà maggiore della 'pendenza longitudinale'"])},
  "hydraulicProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo idraulico"])},
  "drainageArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area di drenaggio"])},
  "systemSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema selezionato"])},
  "switchToAdvanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa alla modalità avanzata"])},
  "switchToStandard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa a Standard"])},
  "selectASystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un sistema"])},
  "selectAFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il fattore di permeabilità"])},
  "selectAFillTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattore di impermeabilità (coefficiente di deflusso) - Questo valore si riferisce alla permeabilità e alla tessitura del materiale superficiale e determina il volume d'acqua che raggiunge il sistema di canali dopo aver attraversato la superficie dell'area."])},
  "permeability": {
    "concretePavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cemento"])},
    "resinSurface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superficie in resina"])},
    "bpCement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autobloccante con giunti di cemento"])},
    "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asfalto"])},
    "bpSand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autobloccante con riempimento in sabbia"])},
    "gravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sassi"])},
    "softLandscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesaggio morbido"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizzazione"])}
  },
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
  "designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progettista"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "materials": {
    "han": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione prodotto"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])}
  },
  "documents": {
    "exportLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta lingua"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzione"])},
    "downloadIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica introduzione"])},
    "hydraulicAnalysis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Analisi idraulica per ", _interpolate(_named("run"))])},
    "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei materiali"])},
    "downloadMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica lista dei materiali"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazione"])},
    "downloadInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica informazioni"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i documenti"])},
    "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica tutti i documenti"])}
  },
  "endCapWithoutOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testata con scarico"])},
  "endcaps": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegliere la testata"])},
    "withOutlet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("material")), " ", _interpolate(_named("diameter")), "mm scarico"])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Non ci sono testate di scarico disponibili per ", _interpolate(_named("channel"))])}
  },
  "switchSide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia lato"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "pipeLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza del tubo"])},
  "pipeLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La lunghezza della tubazione in uscita dal punto di scarico/pozzetto"])},
  "pipeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diametro tubo"])},
  "pipeDiameterTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il diametro della tubazione in uscita dal punto di scarico/pozzetto"])},
  "pipeSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendenza del tubo"])},
  "pipeSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'angolo di uscita del tubo dal drenaggio rispetto al terreno"])},
  "extensionHat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corpo di prolunga"])},
  "extensionHatTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il progettista può aggiungere un elemento di prolunga al punto di scarico utilizzando il pulsante"])},
  "arrowsDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Trascinare/rilasciare (fare clic con il tasto sinistro del mouse) il cerchio rosso per aggiungere il punto di afflusso al percorso del canale.", _interpolate(_list(0)), "Trascinare la freccia blu per aggiungere un accessorio al canale.", _interpolate(_list(0)), "Fare clic con il tasto destro del mouse sul cerchio rosso e sulla freccia blu per eliminare il profilo idraulico."])},
  "pointInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto di\ninnesto"])},
  "accessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessori"])},
  "distanceFromDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza dallo scarico"])},
  "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondità"])},
  "grating": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griglie"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la griglia"])},
    "slot_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione fessure"])},
    "open_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri area"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza griglia"])}
  },
  "positionWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'era almeno un afflusso/accessorio posizionato oltre la nuova lunghezza del canale, quindi sono stati tutti rimossi. Si prega di aggiungerli nuovamente come desiderato."])}
  },
  "accessorySwitchLoss": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli accessori sono stati rimossi per consentire il passaggio alla modalità avanzata. Si prega di aggiungere nuovamente gli accessori desiderati."])}
  },
  "popups": {
    "inflowDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio su afflusso"])},
    "accessoryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli accessori"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonte"])},
    "directPipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione tubo"])},
    "flowRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portata"])},
    "connectionMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di collegamento"])},
    "independent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indipendente"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "trashBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozzetto"])},
    "accessBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozzetto"])},
    "accessCover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento di ispezione"])},
    "endEap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testata"])},
    "endEapWithOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testata con scarico"])},
    "concreteChamber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento in calcestruzzo"])},
    "noAccessoriesAvailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvertenze"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono accessori compatibili con la posizione scelta."])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattare HAURATON per ulteriori informazioni o assistenza."])}
    },
    "distanceTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza dallo scarico è troppo grande"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
  "webLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Links"])},
  "staffAdmin": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff Admin"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "fact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il fatto del giorno: ", _interpolate(_named("fact"))])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulisci"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome completo"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
    "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autobloccante"])},
    "hasAdvancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità avanzata"])},
    "isStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra da ", "{", "first", "}", " a ", "{", "last", "}", " degli utenti ", "{", "totalRecords", "}"])}
  },
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "waterInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresso acqua"])},
  "waterInflowTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa funzione consente di includere un punto di afflusso all'interno dello scarico puntuale. Aggiungere il volume dell'afflusso puntuale in litri/secondo (l/s)."])},
  "selectChannelFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare prima un canale."])},
  "lengthDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I canali selezionati non corrispondono alla lunghezza indicata."])},
  "userSettings": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni utente"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
    "defaultMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità predefinita"])},
    "standardMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "advancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzato"])},
    "userRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intensità di pioggia predefinitia"])},
    "projectRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità intensità di pioggia di progetto"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOG IN"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se si dispone già di un account su questo sito, si prega di effettuare il login qui:"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hai dimenticato la password? Clicca ", _interpolate(_named("here")), " per rimpostarla"])},
    "doNotHaveAccount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Non hai un account? Registrati ", _interpolate(_named("here")), "."])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])}
  },
  "passwordReset": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESET PASSWORD"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il tuo indirizzo e-mail qui sotto e ti invieremo un link da utilizzare per reimpostare la tua password."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "otherwiseLogIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Altrimenti è possibile effettuare il login da ", _interpolate(_named("here")), "."])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Password:"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma Password:"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia Password:"])},
    "invalidLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il link per reimpostare la password non è valido, forse perché è già stato utilizzato."])},
    "invalidLinkTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedere un nuovo reset della password se ancora necessario."])}
  },
  "softwareFeatures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARATTERISTICHE DEL SOFTWARE"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versione UK"])},
    "asia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versione Asia"])},
    "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versione Europo/Internazionale"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FEEDBACK"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desiderate fornire un feedback sul nostro DesignSoftware, fate riferimento a quanto segue:"])},
    "europe&beyond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa & Mondo"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regno Unito"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTATTATECI PER UN SUPPORTO "])}
  },
  "freedomAdvertBox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIBERTÀ DI PROGETTAZIONE, LIBERTÀ DI UTILIZZO"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nostra nuova applicazione web 'DesignSoftware' fornisce ai professionisti del settore un'analisi idraulica rapida e semplice, il dimensionamento dei canali, la progettazione e le specifiche dei prodotti per la gamma di sistemi di drenaggio superficiale HAURATON, lavorando sui propri computer fissi, portatili e tablet."])},
    "justThreeClicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo tre clic per un calcolo…"])}
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione"])},
  "createAnAccountParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se è la prima volta che si utilizza questo sito, si prega di creare un account qui sotto:"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
  "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma Password"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cap"])},
  "registrationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione salvata con successo"])},
  "checkConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllare l'e-mail per ottenere un link di conferma"])},
  "designReassurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garanzia di progettazione"])},
  "reassuranceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DesignSoftware è stato ampiamente testato e la metodologia è stata convalidata da una società di consulenza idraulica indipendente. Il nostro software di progettazione idraulica \"interno\" è stato utilizzato con successo dal personale tecnico HAURATON per oltre 30 anni con totale affidabilità."])},
  "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidenziale"])},
  "confidentialParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli utenti registrati (protetti da password) hanno accesso all'archiviazione sicura sul server dei loro progetti finali. I progetti rimangono strettamente confidenziali, a meno che gli utenti non selezionino l'opzione 'condividi con HAURATON'. Gli utenti registrati hanno la possibilità di condividere un link ai progetti salvati per consentire ad altri colleghi o clienti registrati di visualizzarli. DesignSoftware è stato configurato per essere pienamente conforme alle normative GDPR sulla privacy."])},
  "hydraulicDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROGETTAZIONE IDRAULICA - SISTEMI AGGIUNTIVI"])},
  "hydraulicDesignParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per la progettazione idraulica di ulteriori sistemi HAURATON non inclusi nel nostro DesignSoftware, si prega di fare riferimento alla propria filiale HAURATON o al partner commerciale locale per il supporto tecnico."])},
  "experienceAndExpertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperienza e Competenza"])},
  "experienceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader mondiale nella produzione di sistemi modulari di drenaggio superficiale, i canali di drenaggio HAURATON sono forniti da oltre sessantacinque anni in importanti progetti nei mercati internazionali. Abbiamo sviluppato il nostro primo sistema di drenaggio lineare nel 1956. Da allora il marchio HAURATON è diventato noto in tutto il mondo come punto di riferimento per qualità, affidabilità, durata e servizio."])},
  "help": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HELP"])},
    "topicsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HELP Argomenti"])},
    "videoTutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Tutorial"])},
    "topic": {
      "about_hauraton_designsoftware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni su HAURATON DesignSoftware"])},
      "design_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di calcolo"])},
      "menu_bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funzioni della barra menu"])},
      "managing_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione dei progetti"])},
      "project_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul progetto"])},
      "design_functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funzioni di progettazione"])},
      "design_parameters_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finestra parametri di progetto"])},
      "irregular_catchment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progettare aree di raccolta irregolari"])},
      "rainfall_map_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappa piovosità & Dati (Versione UK)"])},
      "point_inflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punti di innesti"])},
      "adding_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi accessori"])},
      "access_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi di accesso"])},
      "advanced_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa alla progettazione avanzata"])},
      "design_specification_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finestra delle specifiche di progettazione"])},
      "system_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
      "recyfix_hicap_slot_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECYFIX HICAP"])},
      "system_installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schema di posa"])},
      "design_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto alla progettazione"])},
      "allow_flood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentire l'allagamento"])}
    }
  }
}