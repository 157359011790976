export default {
  "language": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angielski"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niemiecki"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francuski"])},
    "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Węgierski"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włoski"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chorwacki"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Słoweński"])}
  },
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy"])},
  "load": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załaduj"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalog projektów - wybierz projekt"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalizacja"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer projektu"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przygotował:"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnij projekt"])},
    "shareText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aby zapewnić dostęp do tego projektu, skopiuj i wklej poniższy link, a następnie wyślij go współpracownikom lub klientom:\n\n", _interpolate(_named("link")), "\n\nW celu uzyskania dostępu osoby te będą musiały zarejestrować się do tej samej wersji oprogramowania Hydraulic Design Software."])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiuj link"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń projekt"])},
    "noProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zapisanych projektów"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlanie od ", "{", "first", "}", " do ", "{", "last", "}", " z ", "{", "totalRecords", "}", " projektów"])},
    "progressBar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Załadowano 0 ciągów z ", _interpolate(_named("total"))]), _normalize(["Załadowano 1 ciąg z ", _interpolate(_named("total"))]), _normalize(["Załadowano ", _interpolate(_named("count")), " ciągów z ", _interpolate(_named("total"))])])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się załadować projektu"])}
  },
  "save": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz projekt"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnij projekt HAURATON"])},
    "advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON zapewnia wsparcie techniczne na wszystkich etapach procesu budowlanego. Nasz wkład może Państwu pomóc w optymalizacji technicznej i ekonomicznej projektu."])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli oczekują Państwo pilnej reakcji po udostepnieniu projektu, prosimy o bezpośredni kontakt z HAURATON:"])},
    "calculateRuns": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Następujące ciągi wymagają obliczeń przez zapisaniem: ", _interpolate(_named("runs"))])},
    "needProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagana nazwa projektu"])},
    "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesja wygasła, spróbuj zalogować się w innej karcie/oknie, a następnie zapisz ponownie"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapis projektu nie powiódł się"])}
  },
  "generic_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Obraz przekroju kanału jest jedynie przykładem służącym do celów ilustracyjnych"])},
  "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa projektu"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
  "projectDetailsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis projektu"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obliczenia"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblicz"])},
  "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesja wygasła, spróbuj zalogować się w innej karcie/oknie, a następnie oblicz ponownie"])},
  "addRunLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj ciąg"])},
  "addRunLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj nowy ciąg do bieżących obliczeń"])},
  "addNotesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj notatkę"])},
  "addNotesLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj uwagi do aktualnie projektowanego ciągu. Uwagi te pojawią się w arkuszach danych hydraulicznych."])},
  "resetLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj"])},
  "resetLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik może przywrócić projektowane parametry aktualnie projektowanego ciągu do ustawień fabrycznych."])},
  "duplicateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiel"])},
  "duplicateLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stwórz kopię aktualnie projektowanego kanału"])},
  "catchmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlewnia"])},
  "channelsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanały"])},
  "resultsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyniki"])},
  "designRunsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
  "materialsListLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zestawienie materiałów"])},
  "documentsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenty"])},
  "selectionOfDrainageSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System odwadniający"])},
  "selectionOfChannelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wielkość kanału"])},
  "confirmedLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzona długość"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr"])},
  "freeOutflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolny odpływ"])},
  "outflowInTrashbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpływ przez studzienkę"])},
  "trashboxes": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz studzienkę"])},
    "chooseShared": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wybierz studzienkę (wspólna z ", _interpolate(_named("shared")), ")"])},
    "withDischarge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Studzienka z odpływem ", _interpolate(_named("discharge")), "mm"])},
    "shareWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wspólne z ", _interpolate(_named("run"))])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Brak ", _interpolate(_named("loading")), "+ studzienki dostępnej dla kanałów ", _interpolate(_named("type"))])}
  },
  "modificationLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modyfikacja elementu odpływowego"])},
  "noBuildInFall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyklucz typy z wbudowanym spadkiem"])},
  "definitionOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widok wiercenia w ostatnim elemencie"])},
  "drillingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiercenie w elemencie odpływowym"])},
  "diameterOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnica wiercenia"])},
  "diameterOfDrillingTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnica otworu do wywiercenia w kanale"])},
  "distanceEdgeToDrillingCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość: od końca kanału do osi otworu"])},
  "bottomAxial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oś od dołu"])},
  "leftSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lewa ściana boczna"])},
  "rightSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prawa ściana boczna"])},
  "cuttingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cięcie elementu odpływowego"])},
  "lengthFromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość od końca"])},
  "angleOfCutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kąt cięcia"])},
  "designParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektowane parametry"])},
  "channelRunLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalizacja"])},
  "channelRunLocationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalizacja projektowanego ciągu / odwodnienia punktowego"])},
  "channelLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość kanału"])},
  "channelLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość kanału. Długość kanału powinna być wielokrotnością 1 m. Nie obejmuje wymiarów systemowej studzienki/komory na początku i końcu kanału."])},
  "catchmentDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość zlewni"])},
  "catchmentDepthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość powierzchni zlewni od dłuższego boku kanału odwadniającego"])},
  "catchmentArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powierzchnia zlewni"])},
  "catchmentAreaTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita powierzchnia zlewni (m²) dla danego ciągu odwodnienia."])},
  "catchment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieregularna powierzchnia zlewni"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlewnia"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Jednolita' - prostokątny obszar zlewni o takiej samej przepuszczalności.", _interpolate(_list(0)), "'Nieregularna' - obszar zlewni składający się z potencjalnie wielu obszarów o innej przepuszczalności.", _interpolate(_list(0)), "Projektowanie nieregularnych obszarów zlewni - patrz plik POMOC."])},
    "irregular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieregularna"])},
    "uniform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednolita"])},
    "editShape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj kształt zlewni"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwórz"])}
  },
  "catchmentWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna powierzchnia zlewni przekroczona"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Powierzchnia zlewni musi być mniejsza niż 100,000m², należy zmniejszyć powierzchnię zlewni o min. ", _interpolate(_named("excess")), "m²"])}
  },
  "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja"])},
  "configurationTT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kaskadowy' - ciąg odwadniający zbudowany z kanałów o różnej głębokości, zwiększającej się w kierunku odpływu.", _interpolate(_list(0)), "'Jednorodny' - ciąg odwadniający zbudowany z kanałów o tej samej szerokości i głębokości.", _interpolate(_list(0)), "Więcej informacji dostępnych w pliku POMOC."])},
  "stepped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaskadowy"])},
  "stepWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwaga"])},
    "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanały betonowe nie mogą być stopniowane (kaskadowe)"])},
    "steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanały stalowe nie mogą być stopniowane (kaskadowe)"])}
  },
  "rainfall": {
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa opadów"])},
    "mapTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij ikonę kropli deszczu, by wyświetlić mapę opadów w Wielkiej Brytanii. Wybierz dokładne dane dotyczące opadów deszczu dla określonych lokalizacji. Wartości natężenia opadów mogą być również wprowadzone ręcznie."])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalizacja"])},
    "intensity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natężenie opadu"])},
    "intensityTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij na 'mm/hr', aby zmienić jednostkę natężenia deszczu na litry/sekundę/hektar (l/s/ha) lub litry/sekundę/metr kwadratowy (l/s/m²)."])},
    "stormDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas trwania deszczu"])},
    "r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r5"])},
    "r15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r15"])},
    "r60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r60"])},
    "default": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw domyślny opad deszczu"])},
      "changeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy chcesz użyć wybranego opadu deszczu jako domyślnego dla kolejnych ciągów odwodnienia?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])}
    }
  },
  "calculationMethod": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda projektowa"])},
    "gvf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manning"])},
    "hrw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HRW"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["HAURATON DesignSoftware zapewnia użytkownikowi możliwość wyboru jednej z dwóch hydraulicznych formuł projektowych do wyznaczania rozmiarów kanałów odwadniających:", _interpolate(_list(0)), "Zmodyfikowana formuła Manning'a-Strickler'a (Manning)", _interpolate(_list(0)), "Hydraulics Research Wallingford (HRW).", _interpolate(_list(0)), "Więcej informacji można znaleźć w pliku 'POMOC'."])}
  },
  "slopeSetting": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia spadku"])},
    "useSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spadek"])},
    "useElevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rzędna"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nachylenie' - dotyczy nachylenia terenu wyrażonego w ", _interpolate(_list(0)), "'Rzędna' - nachylenie terenu obliczone na podstawie zmiany wysokości rzędnych na początku i na końcu ciągu odwadniającego."])}
  },
  "longitudinalSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spadek podłużny"])},
  "longitudinalSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odnosi się do spadku podłużnego nawierzchni, wzdłuż którego ułożony jest kanał odwadniający (nie do nachylenia obszaru zlewni odprowadzającego wodę w kierunku kanału). Jeśli teren jest płaski, wtedy należy wstawić wartość 0%."])},
  "longitudinalSlopeWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalny kąt nachylenia przekroczony"])},
    "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksymalny dopuszczalny spadek, dla którego obowiązuje przyjęta formuła hydrauliczna, wynosi 1 w ", _interpolate(_named("limit"))])}
  },
  "elevationChange": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana wysokości"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Rzędna terenu (wysokość) na końcach kanału, która wraz z długością kanału może być wykorzystana do obliczenia podłużnego nachylenia terenu.", _interpolate(_list(0)), "H = Wartość wzniesienia na kanału.", _interpolate(_list(0)), "D = Wartość wzniesienia na końcu kanału (wylot)"])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początek (czoło)"])},
    "discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpływ"])}
  },
  "elevationWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekroczono maksymalny stopień zmiany wysokości"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Maksymalne dopuszczalne nachylenie, dla którego obowiązuje przyjęta formuła hydrauliczna, wynosi 1 do 30.", _interpolate(_list(0)), "Zmniejsz projektowaną różnicę wysokości lub zwiększ długość ciągu odwodnienia"])}
  },
  "elevationUphillWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początek (czoło) kanału jest poniżej odpływu"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Design Software 3.0 został zaprojektowany dla kanałów ułożonych na płaskim terenie lub o łagodnym dodatnim spadku podłużnym.", _interpolate(_list(0)), "Należy upewnić się, że założona różnica wysokości nie powoduje ujemnego spadku."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamiana wysokości"])}
  },
  "superCriticalWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ważne informacje"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS3.0 został zaprojektowany przy założeniu stopniowo zmieniającego się przepływu w liniowych systemach odwadniających ułożonych na płaskim terenie lub z łagodnymi spadkami podłużnymi."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W przypadku kanałów odwadniających montowanych wzdłuż bardziej stromych zboczy projektant powinien wziąć pod uwagę, że przepływ wody w odwodnieniu liniowym może zmieniać się między warunkami podkrytycznymi i nadkrytycznymi. Może to prowadzić do znacznych turbulencji w korpusie kanału i wystąpienia lokalnych podtopień."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W przypadku projektów ze stromymi podłużnymi spadkami terenu, Design Software 3.0 dobiera kanały o takiej przepustowości, aby pomieścić obliczony przepływ. Jednocześnie nie uwzględnia on gwałtownie zmieniającego się przepływu w kanale oraz wynikających z tego faktu konsekwencji."])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W takich szczególnych sytuacjach zalecamy, aby projektanci stosowali odpowiednią metodologię i formułę w celu przewidywania, prognozowania i uwzględniania warunków przepływu nadkrytycznego i podkrytycznego."])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie pokazuj tego ponownie"])}
  },
  "controlledDischarge": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpływ kontrolowany"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpływ kontrolowany"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kanał szczelinowy HICAP może być wykorzystywany do magazynowania wody i tłumienia przepływu.", _interpolate(_list(0)), "Projektant może zaprojektować kontrolowaną szybkość wypływu z kanału.", _interpolate(_list(0)), "Wartość będzie wyświetlana jako \"0\", jeśli przepływ jest nieograniczony", _interpolate(_list(0)), "Patrz \"Odpływ kontrolowany\" w pliku POMOC."])},
    "stepWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanały, w których zakłada się wykorzystanie odpływu kontrolowanego nie mogą być kaskadowe ani posiadać spadku podłużnego."])},
    "free": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolny odpływ"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szybkość, z jaką woda opuści kanał, jeśli nie jest ograniczony, w oparciu o intensywność opadów i obszar zlewni."])}
    },
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść"])},
    "resetTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść rodzaj odpływu i zmień na wolny odpływ"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblicz"])},
    "calculateTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblicz wartość ograniczenia"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosuj"])},
    "confirmTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj kontrolowanego odpływu dla bieżącego ciągu odwodnienia"])},
    "flooded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żaden kanał odwadniający nie spełnia założonych parametrów"])},
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrany kanał"])},
    "criticalDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krytyczny czas trwania"])},
    "orificeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnica kryzy"])},
    "criticalRainfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krytyczny opad deszczu"])},
    "totalChannelVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita pojemność kanału"])},
    "minimumFreeBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna wolna przestrzeń"])}
  },
  "minimumChannelSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna wielkość kanału"])},
  "minimumChannelSizeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalny rozmiar kanału do wykorzystania w bieżącym ciągu odwodnienia"])},
  "lengthWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna rekomendowana długość kanału przekroczona"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksymalna rekomendowana długość kanału wynosi 500m, proszę skrócić zaprojektowany ciąg odwodnienia o min. ", _interpolate(_named("excess")), "m"])}
  },
  "lengthError": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna długość kanału przekroczona"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksymalna długość kanału dla metody ", _interpolate(_named("method")), " wynosi ", _interpolate(_named("limit")), ", proszę zredukować długość zaprojektowanego ciągu odwodnienia o min. ", _interpolate(_named("excess")), "m"])}
  },
  "divide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podziel"])},
  "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz"])},
  "divide&mergeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegółowe informacje dotyczące dzielenia/łączenia odwadnianych obszarów można znaleźć w pliku POMOC (Projektowanie nieregularnych obszarów zlewni)."])},
  "complexCatchment": {
    "areaNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer zlewni"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość"])},
    "startDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość początkowa"])},
    "endDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość końcowa"])},
    "surfaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powierzchnia zlewni"])},
    "permeability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepuszczalność"])}
  },
  "areaLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość zlewni"])},
  "leftSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlewnia lewa"])},
  "rightSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlewnia prawa"])},
  "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojedyncza"])},
  "designSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specyfikacja projektowa"])},
  "selectedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrany system"])},
  "channelNominalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość nominalna kanału"])},
  "channelInternalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzna szerokość kanału"])},
  "channelInternalDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzna głębokość kanału"])},
  "channelRoughness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szorstkość"])},
  "channelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ kanału"])},
  "invertDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Głębokość kanału"])},
  "overallDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość całkowita"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obciążenia"])},
  "flowVelocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość przepływu"])},
  "channelDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpływ z kanału"])},
  "floodingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość przepełnienia (średnia)"])},
  "dischargeFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpływ"])},
  "usedVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykorzystana pojemność"])},
  "totalSystemVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita pojemność systemu"])},
  "freeboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolna przestrzeń"])},
  "installedGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaprojektowany spadek"])},
  "installedGradientTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli ciąg odwadniający jest zaprojektowany w konfiguracji \"kaskadowej\", różnica wysokości poszczególnych elementów odwodnienia przyczyni się do powstania \"zaprojektowanego spadku\" danego ciągu. W takich przypadkach \"zaprojektowany spadek\" będzie większy niż \"spadek podłużny\" odwodnienia"])},
  "hydraulicProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil hydrauliczny"])},
  "drainageArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odwadniana powierzchnia"])},
  "systemSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór systemu"])},
  "switchToAdvanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz na widok zaawansowany"])},
  "switchToStandard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz na widok standardowy"])},
  "selectASystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz system"])},
  "selectAFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz współczynnik odpływu"])},
  "selectAFillTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Współczynnik odpływu - wartość odnosząca się do przepuszczalności i rodzaju nawierzchni określająca objętość wody, która dopływa do systemu odwadniającego po przepłynięciu przez powierzchnię zlewni."])},
  "permeability": {
    "concretePavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nawierzchnia betonowa"])},
    "resinSurface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nawierzchnia żywiczna"])},
    "bpCement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostka brukowa - spoiny cementowe"])},
    "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nawierzchnia bitumiczna"])},
    "bpSand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostka brukowa - wypełnienie szczelin piaskiem"])},
    "gravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żwir"])},
    "softLandscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teren zielony"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niestandardowa"])}
  },
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalizacja"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer projektu"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaprojektował"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "materials": {
    "han": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer katalogowy"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis produktu"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razem"])}
  },
  "documents": {
    "exportLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język eksportowania"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadzenie"])},
    "downloadIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz wprowadzenie"])},
    "hydraulicAnalysis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Analiza hydrauliczna dla ", _interpolate(_named("run"))])},
    "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zestawienie materiałów"])},
    "downloadMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz zestawienie materiałów"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje"])},
    "downloadInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz informacje"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie dokumenty"])},
    "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz wszystkie dokumenty"])}
  },
  "endCapWithoutOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ścianka czołowa z odpływem"])},
  "endcaps": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz ściankę czołową"])},
    "withOutlet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["odpływ ", _interpolate(_named("material")), " ", _interpolate(_named("diameter")), "mm"])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Brak ścianki czołowej dostępnej dla ", _interpolate(_named("channel"))])}
  },
  "switchSide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana strony"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatwierdź"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymaż"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notatki"])},
  "pipeLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość rury"])},
  "pipeLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość rury wychodzącej z wpustu punktowego / studzienki odpływowej"])},
  "pipeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnica rury"])},
  "pipeDiameterTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnica rury wychodzącej z wpustu punktowego / studzienki odpływowej"])},
  "pipeSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spadek rury"])},
  "pipeSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kąt rury wychodzącej z wpustu punktowego / studzienki odpływowej"])},
  "extensionHat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element podwyższający"])},
  "extensionHatTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektant może dodać element podwyższający do wpustu punktowego za pomocą przełącznika"])},
  "arrowsDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Przeciągnij / upuść czerwone kółko, aby dodać miejsce dodatkowego dopływu do kanału odwadniającego.", _interpolate(_list(0)), "Przeciągnij / upuść niebieską strzałkę, aby dodać wyposażenie dodatkowe do kanału odwadniającego.", _interpolate(_list(0)), "Kliknij prawym przyciskiem myszy czerwone kółko / niebieską strzałkę, aby usunąć elementy z profilu hydraulicznego"])},
  "pointInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopływ\npunktowy"])},
  "accessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyposażenie\ndodatkowe"])},
  "distanceFromDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od odpływu"])},
  "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Głębokość"])},
  "grating": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruszt"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz ruszt"])},
    "slot_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiary szczeliny"])},
    "open_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolne przestrzenie"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość rusztu"])}
  },
  "positionWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwaga"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co najmniej jeden dopływ punktowy / element wyposażenia dodatkowego został umieszczony poza zakresem projektowanego kanału odwadniającego, dlatego wszystkie one zostały usunięte. Prosimy o ich ponowne dodanie w razie potrzeby."])}
  },
  "accessorySwitchLoss": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwaga"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementy wyposażenia dodatkowego zostały usunięte, aby umożliwić przejście do trybu zaawansowanego. Prosimy o ich ponowne dodanie w razie potrzeby."])}
  },
  "popups": {
    "inflowDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły dopływu"])},
    "accessoryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły wyposażenia dodatkowego"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiejscowienie"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródło"])},
    "directPipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włączenie bezpośrednie rury"])},
    "flowRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepływ"])},
    "connectionMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda połączenia"])},
    "independent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niezależnie"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "trashBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studzienka"])},
    "accessBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element rewizyjny"])},
    "accessCover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokrywa rewizyjna"])},
    "endEap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ścianka czołowa"])},
    "endEapWithOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ścianka czołowa z odpływem"])},
    "concreteChamber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studzienka betonowa"])},
    "noAccessoriesAvailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostrzeżenie"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak akcesoriów zgodnych z wybraną pozycją."])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosimy o kontakt z HAURATON w celu uzyskania dodatkowych informacji lub wsparcia."])}
    },
    "distanceTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbyt duża odległość od odpływu"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
  "webLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odniesienia do strony internetowej"])},
  "staffAdmin": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personel administracyjny"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
    "fact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fakt dnia: ", _interpolate(_named("fact"))])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa użytkownika"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię i nazwisko"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
    "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokuj"])},
    "hasAdvancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb zaawansowany"])},
    "isStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracownicy"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlanie od ", "{", "first", "}", " do ", "{", "last", "}", " z ", "{", "totalRecords", "}", " użytkowników"])}
  },
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyloguj"])},
  "waterInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopływ wody"])},
  "waterInflowTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta funkcja umożliwia uwzględnienie dodatkowego dopływu do odwodnienia punktowego. Dodaj wartość dopływu punktowego w litrach na sekundę (l/s)."])},
  "selectChannelFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W pierwszej kolejności wybierz kanał"])},
  "lengthDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrane kanały nie odpowiadają zaprojektowanej długości ciągu."])},
  "userSettings": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia użytkownika"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
    "defaultMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb domyślny"])},
    "standardMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardowy"])},
    "advancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowany"])},
    "userRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domyślne jednostki natężenia opadu"])},
    "projectRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektowane jednostki natężenia opadu"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZALOGUJ SIĘ"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli masz już swoje konto na tej stronie, zaloguj się tutaj:"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa użytkownika"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nie pamiętasz hasła? Kliknij ", _interpolate(_named("here")), ", aby je przywrócić."])},
    "doNotHaveAccount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nie masz konta? Zarejestruj się ", _interpolate(_named("here"))])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tutaj"])}
  },
  "passwordReset": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESETUJ HASŁO"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź swój adres e-mail poniżej, a my wyślemy do Ciebie link, dzięki któremu zresetujesz swoje hasło"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj hasło"])},
    "otherwiseLogIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["W innym przypadku możesz się zalogować z ", _interpolate(_named("here"))])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło:"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź hasło:"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień hasło:"])},
    "invalidLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link do resetowania hasła był nieprawidłowy, prawdopodobnie dlatego, że został już użyty."])},
    "invalidLinkTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poproś ponownie o zresetowanie hasła, jeśli jest to nadal konieczne."])}
  },
  "softwareFeatures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FUNKCJE OPROGRAMOWANIA"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wersja UK"])},
    "asia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wersja azjatycka"])},
    "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wersja europejska/międzynarodowa"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPINIE"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli chcesz przekazać opinię na temat oprogramowania DesignSoftware, najpierw zapoznaj się z poniższymi informacjami:"])},
    "europe&beyond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa & Świat"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wielka Brytania"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKONTAKTUJ SIĘ Z NAMI, ABY UZYSKAĆ DODATKOWE SPECJALISTYCZNE WSPARCIE PROJEKTOWE"])}
  },
  "freedomAdvertBox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWOBODA PROJEKTOWANIA, BEZPŁATNE UŻYTKOWANIE"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasza nowa aplikacja internetowa \"DesignSoftware\" zapewnia profesjonalistom z branży szybką, prostą analizę hydrauliczną, wymiarowanie kanałów odwodnieniowych, przygotowanie projektów i specyfikacji technicznej produktów dla systemów odwadniających HAURATON z wykorzystaniem własnych komputerów stacjonarnych, laptopów i tabletów."])},
    "justThreeClicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystarczą trzy kliknięcia, aby wykonać obliczenia..."])}
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się"])},
  "createAnAccountParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli korzystasz z tej strony po raz pierwszy, utwórz konto poniżej:"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
  "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie hasła"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miasto"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod pocztowy"])},
  "registrationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejestracja przeprowadzona poprawnie"])},
  "checkConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź skrzynkę odbiorczą, na którą wysłaliśmy link aktywacyjny"])},
  "designReassurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niezawodność oprogramowania projektowego"])},
  "reassuranceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprogramowanie DesignSoftware zostało gruntownie przetestowane, a metodologia zatwierdzona przez niezależną firmę konsultingową w dziedzinie hydrauliki. Ponadto, nasze \"wewnętrzne\" oprogramowanie do obliczeń hydraulicznych jest z powodzeniem używane przez inżynierów HAURATON od ponad 30 lat."])},
  "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poufne"])},
  "confidentialParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestrowani użytkownicy (chronieni hasłem) mają dostęp do bezpiecznego przechowywania na serwerze swoich ostatecznych projektów. Projekty pozostają ściśle poufne, chyba że użytkownik zaznaczy opcję \"udostępnij HAURATON\". Zarejestrowani użytkownicy mają możliwość udostępnienia linku do zapisanych projektów innym zarejestrowanym współpracownikom lub klientom. DesignSoftware został skonfigurowany w taki sposób, aby był w pełni zgodny z przepisami RODO dotyczącymi prywatności danych."])},
  "hydraulicDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANALIZA HYDRAULICZNA - DODATKOWE SYSTEMY"])},
  "hydraulicDesignParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W przypadku analizy hydraulicznej dodatkowych systemów HAURATON, nieuwzględnionych w oprogramowaniu DesignSoftware, prosimy o kontakt z HAURATON w celu uzyskania pomocy technicznej."])},
  "experienceAndExpertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiedza i doświadczenie"])},
  "experienceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON, światowy lider w produkcji modułowych systemów odwodnienia powierzchniowego, od ponad sześćdziesięciu pięciu lat dostarcza produkty odwadniające do największych projektów na rynkach międzynarodowych. Nasz pierwszy system odwodnienia liniowego opracowaliśmy w 1956 roku. Od tego czasu marka HAURATON stała się znana na całym świecie jako punkt odniesienia dla jakości, niezawodności, trwałości i jakości obsługi."])},
  "help": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POMOC"])},
    "topicsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POMOC tematy"])},
    "videoTutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filmy instruktażowe"])},
    "topic": {
      "about_hauraton_designsoftware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej informacji o HAURATON Design Software"])},
      "design_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda projektowa"])},
      "menu_bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcje paska MENU"])},
      "managing_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie projektami"])},
      "project_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o projekcie"])},
      "design_functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcje projektu"])},
      "design_parameters_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okno parametrów projektu"])},
      "irregular_catchment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektowanie nieregularnych powierzchni zlewni"])},
      "rainfall_map_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa i dane opadów (wersja UK)"])},
      "point_inflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopływy punktowe"])},
      "adding_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodawanie wyposażenia dodatkowego"])},
      "access_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodawanie elementów rewizyjnych"])},
      "advanced_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełączanie do trybu zaawansowanego"])},
      "design_specification_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okno specyfikacji projektu"])},
      "system_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o systemie"])},
      "recyfix_hicap_slot_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał szczelinowy RECYFIX HICAP"])},
      "system_installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabudowa systemu"])},
      "design_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsparcie projektowe"])},
      "allow_flood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwalanie na przepełnienie kanału"])}
    }
  }
}