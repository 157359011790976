export default {
  "language": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angol"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Német"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francia"])},
    "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olasz"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horvát"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengyel"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szlovén"])}
  },
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új"])},
  "load": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betöltés"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt mappa - projekt betöltés"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyszín"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia szám"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készítő"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztás"])},
    "shareText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hozzáférés engedélyezése a designhoz, másolás és link beillesztése megosztáshoz \n\n", _interpolate(_named("link")), "\n\nRegisztrálni kell ugyanahhoz a verziójú software hozzáféréshez"])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link másolás"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
    "noProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs mentett projekt"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutasd az összes sort (", "{", "first", "}", " to ", "{", "last", "}", " of ", "{", "totalRecords", "}", ")"])},
    "progressBar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Betöltve 0 az ", _interpolate(_named("total")), "-ból"]), _normalize(["Betöltve 1 az ", _interpolate(_named("total")), "-ból"]), _normalize(["Betöltve (count) ", _interpolate(_named("total")), "-ból"])])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikertelen betöltés"])}
  },
  "save": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt mentése"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt megosztás HAURATONnal"])},
    "advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A HAURATON tanácsot adhat a tervezés ellenőrzése során, és / vagy segíthet a legköltséghatékonyabb csatornakonfigurációk elérésében"])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztás után sürgős visszajelzés esetén keresse HAURATON:"])},
    "calculateRuns": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A mentés előtt a következőket kell kiszámítani: ", _interpolate(_named("runs"))])},
    "needProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt megnevezés!"])},
    "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkamenet lejárt, bejelentkezés új ablakban és mentés újra"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikertelen mentés…"])}
  },
  "generic_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A keresztmetszeti kép általános, és csak illusztrációként szolgál"])},
  "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt megnevezése"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt  "])},
  "projectDetailsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt leírása"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
  "calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalkuláció"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalkulál"])},
  "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkamenet lejárt, bejelentkezés új ablakban és mentés újra"])},
  "addRunLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új szakasz hozzáadása"])},
  "addRunLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új szakasz hozzáadása meglévő szerkesztéshez"])},
  "addNotesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés "])},
  "addNotesLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon megjegyzéseket az ereszcsatorna tervezéshez. Ezek megjelennek a hidraulikus számítással kapcsolatos dokumentumokon."])},
  "resetLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszaállítás  "])},
  "resetLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználó visszaállíthatja a kiválasztott csatorna adatait és tervezési paramétereit az alapbeállításokra."])},
  "duplicateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikálás"])},
  "duplicateLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolás"])},
  "catchmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vízgyűjtő"])},
  "channelsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna"])},
  "resultsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredmény"])},
  "designRunsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piszkozat"])},
  "materialsListLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyaglista"])},
  "documentsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentumok"])},
  "selectionOfDrainageSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vízelvezető rendszer"])},
  "selectionOfChannelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna méret"])},
  "confirmedLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrzött Hossz"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hossz"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasság"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No."])},
  "freeOutflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szabad kiáramlás"])},
  "outflowInTrashbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiáramlás "])},
  "trashboxes": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szennyfogó doboz kiválasztása"])},
    "chooseShared": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Szennyfogó doboz kiválasztása (megosztás ", _interpolate(_named("shared")), ")"])},
    "withDischarge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Szennyfogó doboz kifolyóval ", _interpolate(_named("discharge")), "mm"])},
    "shareWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Megosztás ", _interpolate(_named("run"))])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehhez az ereszcsatorna-rendszerhez nem áll rendelkezésre szennyfogó doboz"])}
  },
  "modificationLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módoítás a kifolyónál"])},
  "noBuildInFall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejtős ereszcsatornák nélkül"])},
  "definitionOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ereszcsatorna elem nézete furattal"])},
  "drillingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeresztő nyílással ellátott elem"])},
  "diameterOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furat átmérője"])},
  "diameterOfDrillingTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csatornába fúrandó furat átmérője"])},
  "distanceEdgeToDrillingCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Távolság: a csatorna vége a fúrás középpontjáig"])},
  "bottomAxial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alsó tengely"])},
  "leftSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bal oldali ereszcsatorna fal"])},
  "rightSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobb oldali ereszcsatorna fal"])},
  "cuttingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méretre vágott elem"])},
  "lengthFromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hossz a végétől"])},
  "angleOfCutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vágási szög"])},
  "designParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervezési paraméterek"])},
  "channelRunLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hely"])},
  "channelRunLocationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beépítési pozíció ereszcsatorna vezeték / pont vízelvezetés"])},
  "channelLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna hossza"])},
  "channelLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csatorna karakterláncának hossza 1 méter többszöröse legyen. Ez a hossz nem tartalmazza a bemeneti doboz hosszát"])},
  "catchmentDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vízgyűjtő terület mélysége"])},
  "catchmentDepthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vízgyűjtő terület távolsága a csatorna egyenkénti hosszú oldalától."])},
  "catchmentArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vízgyűjtő terület"])},
  "catchmentAreaTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csatornavezeték által lecsapolandó teljes terület (m²). Feltételezzük, hogy a vízgyűjtő terület téglalap alakú"])},
  "catchment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szabálytalan vízgyűjtő terület"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terület típusa"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["homogén' - Négyszögletes vízgyűjtő terület, amelynek lefolyási együtthatója mindenhol azonos.\n'változó': Olyan vízgyűjtő terület, amely több különböző vízgyűjtő mélységből és esetleg különböző lefolyási együtthatókból állhat.\nLásd: \"Változó vízgyűjtő területek kiszámítása a SÚGÓFÁJLBAN\"."])},
    "irregular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Változó"])},
    "uniform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homogén"])},
    "editShape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vízgyűjtő terület alakjának szerkesztése"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megnyit"])}
  },
  "catchmentWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túllépte a legnagyobb vízgyűjtő területet"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A vízgyűjtő területnek 100 000 m²-nél kisebbnek kell lennie, kérjük, csökkentse a tervezést legalább ", _interpolate(_named("excess")), "m²-rel"])}
  },
  "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguráció"])},
  "configurationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lépcsős lejtés' - különböző fenékmélységű csatornatípusokból álló csatornahúr, a kimenet irányában történő fokozás\n'Azonos típus' – azonos fenékmélységű (és szélességű) egységes csatornatípusokból álló csatornahúr \nTovábbi információt a súgófájlban talál."])},
  "stepped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lépcsős  "])},
  "stepWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jegyzet"])},
    "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lépcsős lejtés beton  ereszcsatornáknál nem lehetséges"])},
    "steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lépcsős lejtés fémes ereszcsatornáknál nem lehetséges"])}
  },
  "rainfall": {
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esőzések térképe"])},
    "mapTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson az esőcsepp ikonra az Egyesült Királyság csapadéktérképéhez. Válassza ki az egyes helyek pontos csapadékadatait az Egyesült Királyság csapadéktérképére kattintva. A csapadék intenzitásának értékei manuálisan is megadhatók."])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hely"])},
    "intensity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csapadék intenzitása"])},
    "intensityTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson a \"mm/hr\" szövegre, ha a csapadék mérését liter/másodperc/hektár\" (l/(s*ha)) vagy \"liter/másodperc/m²\" (l/s/m²) értékre szeretné módosítani."])},
    "stormDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vihar időtartama"])},
    "r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r5"])},
    "r15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r15"])},
    "r60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r60"])},
    "default": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapértelmezett csapadék beállítása"])},
      "changeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szeretné a kiválasztott csapadékot alapértelmezettként használni az új futtatásokhoz?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem"])}
    }
  },
  "calculationMethod": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervezés"])},
    "gvf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMS"])},
    "hrw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HRW"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A HAURATON DesignSoftware lehetőséget biztosít a felhasználó számára, hogy két hidraulikus tervezési képlet közül válasszon a csatornaméretek kiszámításához:\nMódosított Gauckler-Manning-Strickler (GMS)\nHidraulika Kutatás, Wallingford (HRW).\nTovábbi információkért lásd a \"Súgó\" fájlt.\""])}
  },
  "slopeSetting": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejtő beállítása"])},
    "useSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejtő  "])},
    "useElevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emelkedés"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejtés' - a csatorna hosszanti lejtése százalékos értékben\n'Emelkedés' - A talajszint függőleges magasságváltozásával kiszámított lejtés a csatornapálya elején és végén."])}
  },
  "longitudinalSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosszanti lejtő"])},
  "longitudinalSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hosszanti felszíni lejtésre vonatkozik, amely mentén a csatornát lefektetik (nem pedig a vízgyűjtő területnek a csatorna felé lefolyó lejtésére). Ha a talaj vízszintes, írjon be 0% -ot."])},
  "longitudinalSlopeWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túllépte a maximális lejtési szöget"])},
    "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A legnagyobb megengedett lejtés, amelyre a hidraulikaelmélet érvényes, 1 a ", _interpolate(_named("limit")), "-ben"])}
  },
  "elevationChange": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasságváltozás"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A talaj függőleges magassága a pálya végén, amely a csatorna hosszával együtt felhasználható a hosszanti talajlejtés kiszámításához\nH = Magassági érték a menet fejénél (kezdetén)\nD = Magassági érték a menet kisüléskor"])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felső"])},
    "discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kifolyó"])}
  },
  "elevationWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A maximális magasságváltozási arány túllépve"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A legnagyobb megengedett lejtés, amelyre a hidraulikus elmélet érvényes, 1 a 30-hoz.\nKérjük, csökkentse a terv magasságváltozását, vagy növelje annak hosszát."])}
  },
  "elevationUphillWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csatornafej a kifolyó alatt van"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A DS3.0-t sík talaj vagy enyhe pozitív hosszanti emelkedők mentén telepített csatornákhoz tervezték.\nKérjük, győződjön meg arról, hogy a magasságváltozás nem eredményez negatív lejtést."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szintkülönbség cseréje"])}
  },
  "superCriticalWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fontos információ"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A DS3.0-t úgy tervezték, hogy fokozatosan változó áramlást feltételezzen a vízszintes talaj mentén vagy enyhe hosszanti lejtésű lineáris vízelvezető rendszerekben."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A meredekebb lejtőkön telepített csatornajáratok esetében a tervezőnek figyelembe kell vennie, hogy a csatornarendszeren belüli vízáramlás változhat a kritikus és a szuperkritikus körülmények között. Ez nagyobb turbulenciához vezethet a csatorna testén belül, és lehetséges lokalizált felszíni elárasztáshoz."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A meredekebb hosszanti talajlejtésű projektek esetében a DS3.0 elegendő kapacitású csatornakialakítást biztosít a számított térfogatáram befogadására, de nem veszi figyelembe a csatornatesten belül esetlegesen bekövetkező gyorsan változó áramlást, amely ennek következményeivel járhat."])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ilyen speciális helyzetekben azt tanácsoljuk, hogy a projekttervezők a megfelelő módszertant és képletet használják a szuperkritikus áramlási feltételek előrejelzésére, előrejelzésére és figyelembevételére."])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne jelenjen meg többé"])}
  },
  "controlledDischarge": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrzött kifolyó"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrzött kifolyó"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A HICAP Slot Channel víztárolásra és csillapításra használható.\nA tervező szabályozhatja a csatorna futásának kifolyási sebességét.\nAz érték 0\" lesz, ha a folyamat nincs korlátozva\nLásd a \"Szabályozott kisülés\" részt a HELP fájlban.\""])},
    "stepWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ellenőrzött kifolyást használó kialakítások nem lehetnek lépcsőzetesek vagy lejtősek"])},
    "free": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szabad kifolyás"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az a sebesség, amellyel a víz korlátozás nélkül kilép a csatornából, a csapadékintenzitás és a vízgyűjtő terület alapján"])}
    },
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újra"])},
    "resetTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszaállítás szabad kifolyásra"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalkulál"])},
    "calculateTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számítsa ki a határérték eredményét"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használ"])},
    "confirmTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használja a szabályozott kifolyási áramlást az aktuális futáshoz"])},
    "flooded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyetlen csatornát sem lehet úgy kialakítani, hogy megfeleljen a jelenlegi paramétereknek"])},
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiválasztott ereszcsatorna"])},
    "criticalDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritikus időtartam"])},
    "orificeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyílás átmérője"])},
    "criticalRainfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritikus eső"])},
    "totalChannelVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ereszcsatorna teljes térfogata"])},
    "minimumFreeBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum szabad terület"])}
  },
  "minimumChannelSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum csatorna méret"])},
  "minimumChannelSizeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az aktuális futtatáshoz használandó csatorna minimális mérete"])},
  "lengthWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túllépte a maximális ajánlott csatornahosszt"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csatornahúr ajánlott maximális hossza 500 m"])}
  },
  "lengthError": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túllépte a csatorna maximális hosszát"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A maximum csatornahossz a ", _interpolate(_named("method")), " tervezéshez ", _interpolate(_named("limit")), "m, kérjük, csökkentse a tervet legalább ", _interpolate(_named("excess")), "m-mel"])}
  },
  "divide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osztás"])},
  "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyesítés"])},
  "divide&mergeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vízgyűjtők felosztásával/összekapcsolásával kapcsolatos részletekért lásd a HELP fájlt (A részekre osztott vízgyűjtők kiszámítása)"])},
  "complexCatchment": {
    "areaNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terület száma"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hossz "])},
    "startDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mélység az elején"])},
    "endDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mélység a végén"])},
    "surfaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terület"])},
    "permeability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áteresztőképesség"])}
  },
  "areaLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A terület hossza"])},
  "leftSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bal"])},
  "rightSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobb"])},
  "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azonos típus"])},
  "designSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidraulikus kialakítás"])},
  "selectedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Választott rendszer"])},
  "channelNominalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna névleges szélessége"])},
  "channelInternalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna belső szélessége"])},
  "channelInternalDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna belső mélysége"])},
  "channelRoughness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érdesség"])},
  "channelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna típus"])},
  "invertDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talpmélység"])},
  "overallDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes mélység"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betöltés"])},
  "flowVelocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áramlási sebesség a kimenetnél"])},
  "channelDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna kifolyó"])},
  "floodingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzávetőleges folyási sebesség"])},
  "dischargeFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kifolyási áramlás"])},
  "usedVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használt térfogat"])},
  "totalSystemVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes rendszer térfogat"])},
  "freeboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szabad terület"])},
  "installedGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erdemény"])},
  "installedGradientTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a csatornát \"lépcsős lejtőként\" tervezték, akkor a különböző típusú csatornák magasságkülönbségei hozzájárulnak a \"kapott lejtéshez\". Ilyen esetekben a \"Kapott lejtő\" nagyobb, mint a \"Hosszanti lejtő\"."])},
  "hydraulicProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidraulikus profil"])},
  "drainageArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vízelvezető terület"])},
  "systemSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ereszcsatorna rendszer kiválasztása"])},
  "switchToAdvanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váltás speciálisra"])},
  "switchToStandard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váltás normálra"])},
  "selectASystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendszer választás"])},
  "selectAFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áteresztőképességi tényező"])},
  "selectAFillTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lefolyási együttható - Ez az érték a talajfelszín anyagának áteresztőképességére és jellegére utal, és meghatározza azt a vízmennyiséget, amely eléri a vízelvezető rendszert, miután az átáramlott a vízgyűjtő felületén."])},
  "permeability": {
    "concretePavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beton felület"])},
    "resinSurface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epoxi felület"])},
    "bpCement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Útburkoló kövek habarccsal"])},
    "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aszfalt"])},
    "bpSand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Útburkoló kövek homokkal"])},
    "gravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kavicsos felület"])},
    "softLandscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pázsit"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyedi"])}
  },
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyszín"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekszám"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég"])},
  "designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervező"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "materials": {
    "han": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cikkszám"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termék leírás"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyiség"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])}
  },
  "documents": {
    "exportLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemutatkozás"])},
    "downloadIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letöltési útmutató"])},
    "hydraulicAnalysis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hidraulikaelemzés ", _interpolate(_named("run"))])},
    "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyagok listája"])},
    "downloadMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letöltés anyagok listája"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információ"])},
    "downloadInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információ letöltése"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összes dokumentum"])},
    "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összes dokumentum letöltése"])}
  },
  "endCapWithoutOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végzáró leeresztővel"])},
  "endcaps": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végzáró választás"])},
    "withOutlet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("material")), " ", _interpolate(_named("diameter")), "mm leeresztő"])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nem elérhető a végzáró leeresztővel ", _interpolate(_named("channel"))])}
  },
  "switchSide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldal váltás"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősít"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégsem"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogad"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töröl"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés"])},
  "pipeLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cső hossz"])},
  "pipeLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pontleeresztő/bemeneti dobozhoz csatlakoztatott cső hossza"])},
  "pipeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cső átmérő"])},
  "pipeDiameterTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pontleeresztő/bemeneti dobozhoz csatlakoztatott cső átmérője"])},
  "pipeSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cső lejtése"])},
  "pipeSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pontleeresztő/bemeneti dobozhoz csatlakoztatott cső szöge a felülethez viszonyítva"])},
  "extensionHat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosszabbító"])},
  "extensionHatTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tervező a váltógombbal adhat hozzá egy hosszabbítót a pontleeresztéshez"])},
  "arrowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Húzza el a piros kört (bal egérgombbal), hogy pontos bemenetet adjon az ereszcsatorna vonalához.\nHúzza a kék nyilat (bal egérgombbal) tartozékok hozzáadásához az ereszcsatorna karakterláncához.\nKattintson a jobb gombbal a piros körre/kék nyílra, hogy törölje azt az ereszcsatorna karakterláncából."])},
  "pointInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beáramlási\npont"])},
  "accessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiegészítők"])},
  "distanceFromDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kifolyótól való távolság"])},
  "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mélység"])},
  "grating": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rács"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rács választás"])},
    "slot_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyílás méretei"])},
    "open_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemeneti keresztmetszet"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rács hossza"])}
  },
  "positionWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figyelmeztetés"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább egy beáramlás/tartozék az újonnan megadott csatornahosszon túl helyezkedett el, ezért mindegyiket eltávolítottuk. Kérjük, adja hozzá újra"])}
  },
  "accessorySwitchLoss": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figyelmeztetés"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tartozékot eltávolítottuk, hogy lehetővé tegyük a speciális módba váltást. Kérjük, adja hozzá újra"])}
  },
  "popups": {
    "inflowDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beáramlás részletei"])},
    "accessoryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartozék részletei"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozíció"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrás"])},
    "directPipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közvetlen cső"])},
    "flowRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áramlási sebesség"])},
    "connectionMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozási mód"])},
    "independent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Független"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
    "trashBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szennyfogó doboz"])},
    "accessBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrző doboz"])},
    "accessCover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrző doboz fedél"])},
    "endEap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végzáró"])},
    "endEapWithOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végzáró kifolyóval"])},
    "concreteChamber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beton kamra"])},
    "noAccessoriesAvailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figyelem"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek olyan tartozékok, amelyek kompatibilisek a kiválasztott pozícióval."])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, vegye fel a kapcsolatot a HAURATON-nal további információkért vagy segítségért."])}
    },
    "distanceTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Távolság a kimenettől túl nagy"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "webLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkek"])},
  "staffAdmin": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmazotti rendszergazda"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
    "fact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A nap ténye: ", _interpolate(_named("fact"))])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töröl"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználónév"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ország"])},
    "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokkol"])},
    "hasAdvancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normál mód"])},
    "isStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkavállaló"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutat ", "{", "first", "}", " to ", "{", "last", "}", " of ", "{", "totalRecords", "}", " felhasználókat"])}
  },
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
  "waterInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vízbeáramlás"])},
  "waterInflowTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a funkció lehetőséget kínál egy további pontbeáramlás integrálására. Adja hozzá a pontbeáramlás térfogatát liter/másodpercben (l/s)."])},
  "selectChannelFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, először válasszon ki egy ereszcsatornát."])},
  "lengthDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kijelölt csatornák nem egyeznek meg az ereszcsatorna megadott hosszával."])},
  "userSettings": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó beállítások"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
    "defaultMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapértelmezett"])},
    "standardMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alap "])},
    "advancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bővített"])},
    "userRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapértelmezett csapadékintenzitási egységek"])},
    "projectRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csapadékintenzitás / esőesemény projekthez kapcsolódó egysége"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belépés"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha már rendelkezik fiókkal ezen az oldalon, kérjük, jelentkezzen be itt:"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználónév"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elfelejtette jelszavát? Kattintson a ", _interpolate(_named("here")), " a visszaállításhoz."])},
    "doNotHaveAccount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Még nincs fiókja? Regisztráljon ", _interpolate(_named("here")), "."])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["itt"])}
  },
  "passwordReset": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó visszaállítása"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add meg alább az e-mail-címedet, és küldünk egy linket, amellyel visszaállíthatod a jelszavadat."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó visszaállítása"])},
    "otherwiseLogIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ellenkező esetben beléphet innen ", _interpolate(_named("here"))])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó megerősítése:"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó cseréje:"])},
    "invalidLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszó-visszaállítási hivatkozás érvénytelen volt, valószínűleg azért, mert már használatban van."])},
    "invalidLinkTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, kérjen új jelszó visszaállítást, ha továbbra is szükséges."])}
  },
  "softwareFeatures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZOFTVER FUNKCIÓK"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK Verzió"])},
    "asia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ázsia Verzió"])},
    "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Európai verzió"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzés"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha visszajelzést szeretne küldeni a DesignSoftware-rel kapcsolatban, kérjük, olvassa el a következőket:"])},
    "europe&beyond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Európa & Világ"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyesült Királyság"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VEGYE FEL VELÜNK A KAPCSOLATOT SPECIÁLIS TERVEZÉSI TÁMOGATÁSÉRT"])}
  },
  "freedomAdvertBox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A TERVEZÉS SZABADSÁGA, A SZABAD HASZNÁLAT"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új webalapú alkalmazásunk, a \"DesignSoftware\" gyors és egyszerű hidraulikus tervezést, csatornaméretezést, projekttervezést és termékspecifikációt biztosít az ipari szakemberek számára a HAURATON felszíni vízelvezető rendszereihez, miközben saját asztali számítógépeiken, laptopjaikon és táblagépeiken dolgoznak."])},
    "justThreeClicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak három kattintás a számításhoz..."])}
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció"])},
  "createAnAccountParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha először használja ezt az oldalt, kérjük, hozzon létre egy fiókot alább:"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresztnév"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezetéknév"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ország"])},
  "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó megerősítése:"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Város"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítószám"])},
  "registrationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A regisztráció sikeresen mentve"])},
  "checkConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, ellenőrizze e-mailjeit a megerősítő linkért"])},
  "designReassurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tervezőszoftver megbízhatósága"])},
  "reassuranceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tervezőszoftvert széles körben tesztelték és a módszertant egy független hidraulika tanácsadó cég validálta. \"Házon belüli\" hidraulikus szoftverünket a HAURATON technikusai több mint 30 éve sikeresen és abszolút megbízhatóan használják."])},
  "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizalmas"])},
  "confidentialParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A regisztrált felhasználók (jelszóval védett) hozzáférhetnek a biztonságos szervertárolóhoz a végleges terveikkel kapcsolatban. A formatervezési minták szigorúan bizalmasak maradnak, kivéve, ha a felhasználók bejelölik a \"megosztás a HAURATONNAL\" lehetőséget. A regisztrált felhasználóknak lehetőségük van arra, hogy megosszák a mentett tervekre mutató linket, hogy más regisztrált kollégák vagy ügyfelek megtekinthessék őket. A DesignSoftware úgy lett konfigurálva, hogy teljes mértékben megfeleljen a GDPR \"adatvédelmi\" előírásainak."])},
  "hydraulicDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIDRAULIKAI TERVEZÉS - KIEGÉSZÍTŐ RENDSZEREK"])},
  "hydraulicDesignParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A DesignSoftware szoftverünkben nem szereplő további HAURATON rendszerek hidraulikai tervezéséhez kérjük, forduljon a HAURATON leányvállalatához vagy helyi kereskedelmi partneréhez műszaki támogatásért."])},
  "experienceAndExpertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapsztalat és szakértelem"])},
  "experienceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A moduláris felszíni vízelvezető rendszerek gyártásában világelső HAURATON vízelvezető termékeket már több mint hatvanöt éve szállítanak a nemzetközi piacokon zajló nagy projektekhez. Az első lineáris vízelvezető rendszerünket 1956-ban fejlesztettük ki. Azóta a HAURATON márka világszerte a minőség, a megbízhatóság, a tartósság és a szolgáltatás mércéjeként vált ismertté."])},
  "help": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segítség"])},
    "topicsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segítség"])},
    "videoTutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktató videók"])},
    "topic": {
      "about_hauraton_designsoftware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A HAURATON Design Software-ről"])},
      "design_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számítási módszer"])},
      "menu_bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü funkciók"])},
      "managing_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektk kezelése"])},
      "project_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt információk"])},
      "design_functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design funkciók"])},
      "design_parameters_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervezési paraméterek ablak"])},
      "irregular_catchment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szabálytalan vízgyűjtőterületek tervezése"])},
      "rainfall_map_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csapadéktérkép és adataok (Anglia)"])},
      "point_inflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befolyási pontok"])},
      "adding_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartozákok hozzáadása"])},
      "access_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tisztítási pontok"])},
      "advanced_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Javasolt design-ra váltás"])},
      "design_specification_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design ablak"])},
      "system_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termékcsoport információ"])},
      "recyfix_hicap_slot_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECYFIX HICAP"])},
      "system_installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendszer telepítése"])},
      "design_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design támogatás"])},
      "allow_flood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túlfolyás engedélyezése"])}
    }
  }
}