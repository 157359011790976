<template>
  <div id="canvas-wrapper">
    <canvas
        ref="canvas"
        :width="canvasWidth_px"
        :height="canvasHeight_px"
        id="canvas"
    ></canvas>
    <inflow-popup
        v-if="displayInflowPopup"
        :popupPosition="popupPosition"
        :selected-inflow-id="currentInflowId"
        @inflow-cancel="handleCancelInflow($event)"
        @inflow-accept="handleInflowAccept($event)"
    />
    <accessory-popup
        v-if="displayAccessoryPopup"
        :popupPosition="popupPosition"
        :selected-accessory-id="currentAccessoryId"
        @accessory-cancel="handleCancelAccessory($event)"
        @accessory-accept="handleAccessoryAccept($event)"
    />
    <Dialog :header="$t('popups.noAccessoriesAvailable.title')" v-model:visible="showNoAccessories">
      <p>{{ $t('popups.noAccessoriesAvailable.message') }}</p>
      <p>{{ $t('popups.noAccessoriesAvailable.contact') }}</p>
      <template #footer>
        <Button :label="$t('ok')" icon="pi pi-check" autofocus @click="showNoAccessories = false"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import 'core-js/features/math/clamp';
import {fabric} from "fabric";
import {v4 as uuidv4} from 'uuid';
import InflowPopup from "./InflowPopup";
import AccessoryPopup from "./AccessoryPopup";
import {Run} from "@/store";
import {BASE_URL} from "@/constants";
import {request as fetch} from "@/auth";
import saveIcon from "@/assets/images/save_icon.svg";
import {mapGetters} from 'vuex';


/**
 * The width trashboxes should be drawn with, in pixels
 * @type {number}
 */
const trashboxWidth = 40;
/**
 * The depth/vertical height trashboxes should be drawn with, in pixels
 * @type {number}
 */
const trashboxDepth = 25;
/**
 * Whether drawn trashboxes slope with the attached channel
 * @type {boolean}
 */
const slopeTrashboxes = false;

/**
 * Round the given number to 1 decimal place
 *
 * @param {number} value The number to round
 * @returns {number} The rounded version of the number
 */
function to1DP(value) {
    return +value.toFixed(1);
}

export default {
  name: "HydraulicProfile",
  components: {AccessoryPopup, InflowPopup},
  props: {
    'selectedRun': {
      type: Run,
      required: true,
    },
    'drawShort': {
      type: Boolean,
      default() {
        return window.innerWidth < 1900;
      },
    }
  },
  inject: ['doCalculate'],
  data() {
    return {
      paddingTop_px: 70,
      strokeWidth: 1,
      leftPadding: 140,
      displayInflowPopup: false,
      displayAccessoryPopup: false,
      showNoAccessories: false,
      isSliding: false,
      popupPosition: {
        x: 0, y: 0,
      },

      // current accessory data
      currentAccessoryId: '',
      // current inflow data
      currentInflowId: '',

      /////////////////////////////////////////////////////////////////////////////////

      inflowHeights: [], // need to define at what px height to snap the inflow based on step height
      hasJustCalculated: false, // need to redraw non inflow related accessories labels
    }
  },
  async mounted() {
    await document.fonts.load('12px interstate-light');
    const ref = this.$refs.canvas;
    this.canvas = new fabric.Canvas(ref, {
      fireRightClick: true,
      stopContextMenu: true,
    }).on('selection:created', ({target: {type: selectionType}}) => {
      if (selectionType === 'activeSelection') {
        this.canvas.discardActiveObject();
      }
    }).on('selection:updated', ({target: {type: selectionType}}) => {
      if (selectionType === 'activeSelection') {
        this.canvas.discardActiveObject();
      }
    });
    this.populateDrawing();
  },
  methods: {
    populateDrawing() {
      this.deleteAllObjects();
      this.drawProfile();
      this.drawInflow(true, 20, this.paddingTop_px, true,);
      if (this.selectedRun.selectedSystem?.product_type !== 'M') {
        this.drawAccessory('#1c80cf', true, 20, this.paddingTop_px + 50, true, undefined, false);
      }
      this.drawAccessoryLabels();
      this.drawChannelDetails();
      this.refreshInflows();
      this.refreshAccessories();
    },
    refreshAccessories() {
      // redraws arrows based on stored values
      this.canvas.remove(...this.canvas.getObjects().filter(o => o.class === 'accessory' && !o.isStatic));
      for (const accessory of this.accessories) {
        if (accessory.type === 'no-automatic') continue;
        let selectable = !this.inflowsWithAccessBox.some(i => i.accessoryId === accessory.id);
        this.drawAccessory('#1c80cf', selectable, (this.leftPadding + this.profileWidth_px) - this.mToPxHorizontal(accessory.distance_m) - 10,
                          this.paddingTop_px - 25, false, accessory.id, false);
      }
    },
    handleInflowAccept(event) {
      this.displayInflowPopup = false

      // If the inflow has own accessory, update the inflow position as well as the label position and text displaying
      const accessoryToUpdate = this.inflowsWithAccessBox.find(inflow => inflow.id === this.currentInflowId);
      let newAccessoryID = undefined;
      if (accessoryToUpdate !== undefined) {
        if (event.connection === 'access-box') {
          this.$store.commit('updateAccessory', {
            id: accessoryToUpdate.accessoryId,
            distance_m: event.position,
            type: `${event.position === 0 ? 'trash' : 'access'}-box`,
          })
        } else {
          this.$store.commit('removeAccessory', accessoryToUpdate.accessoryId);
          newAccessoryID = null;
        }
      } else if (event.connection === 'access-box') {
        const newDistance_m = event.position;
        const endOfProfile_px = this.leftPadding + this.profileWidth_px - this.strokeWidth - 10;
        const distance_px = this.profileWidth_px * newDistance_m / this.selectedRun.channel_length_m;
        const newId = this.drawAccessory('#1c80cf', false, endOfProfile_px - distance_px,
            this.paddingTop_px - 25, false, null, false);
        this.$store.commit('addAccessory', {
          id: newId, distance_m: newDistance_m, type: `${newDistance_m === 0 ? 'trash' : 'access'}-box`,
        });
        newAccessoryID = newId;
        //Inflows cause a full recalculation, so there is no need to update the materials (despite an accessory change)
      }

      this.$store.commit('updateInflow', {
        id: this.currentInflowId,
        distance_m: event.position,
        source: event.source,
        flow_lps: event.flow_lps,
        connection: event.connection,
        newAccessoryID,
        toBeConfirmed: false
      })

      this.$store.dispatch('doCalculate').catch(() => {
        //The inflow caused the design not to work, mark it as iffy then recalculate (without it)
        this.$store.commit('updateInflow', {
          id: this.currentInflowId,
          toBeConfirmed: true,
        });
        this.doCalculate();
      }).finally(() => this.currentInflowId = null);
    },
    handleCancelAccessory({onlyClose, noneAvailable}) {
      this.displayAccessoryPopup = false

      if (!onlyClose) {
        this.removeAccessory(this.currentAccessoryId);
      }

      this.currentAccessoryId = null;
      if (noneAvailable) this.showNoAccessories = true;
    },
    handleCancelInflow(onlyClose) {
      this.displayInflowPopup = false

      if (!onlyClose) {
        this.removeInflow(this.currentInflowId);
      }

      this.currentInflowId = null;
    },
    removeInflow(id) {
      const inflow = this.inflows.find(inflow => inflow.id === id);
      this.$store.commit('removeInflow', id);
      this.refreshInflows(); //The inflow group also needs re-creating... so this is easiest

      if (inflow?.accessoryId) {
        // The inflow has its own accessory so delete the arrow...
        this.$store.commit('removeAccessory', inflow.accessoryId);
        this.refreshAccessories();
      }
    },
    removeAccessory(id) {
      /** @type Accessory */
      const accessory = this.accessories.find(accessory => accessory.id === id);
      if (accessory?.automatic) {//Convert automatic accessory to negative accessory marker
        if (accessory.distance_m === 0) {
          switch (this.selectedRun.dischargeSetting) {//Don't allow discharge accessories to be removed
            case 'outflow-in-trashbox':
              if (accessory.type === 'trash-box') return;
              break;

            case 'end-cap-with-outlet':
              if (accessory.type === 'end-cap-with-outlet') return;
              break;
          }
        }
        this.$store.commit('cancelAutomaticAccessory', id);
      } else {
        this.$store.commit('removeAccessory', id);
      }
      if (accessory && !accessory.toBeConfirmed) this.updateMaterials();
      this.canvas.remove(...this.canvas.getObjects().filter(o => {
        return o.id === id || o.id === id + '-label';
      }));

      for (const inflow of this.inflowsWithAccessBox) {
        if (inflow.accessoryId === id) {
          // The accessory is for an inflow so delete the circle...
          this.$store.commit('removeInflow', inflow.id);
          this.refreshInflows();
          break;
        }
      }
    },
    handleAccessoryAccept(event) {
      this.displayAccessoryPopup = false

      const materialChange = this.accessories.find(accessory => accessory.id === this.currentAccessoryId)?.type !== event.type;
      this.$store.commit('updateAccessory', {
        id: this.currentAccessoryId,
        type: event.type,
        distance_m: event.position,
        toBeConfirmed: false,
      })
      if (materialChange) this.updateMaterials();

      this.refreshAccessories();
      this.currentAccessoryId = null;
    },
    async updateMaterials() {
      const response = await fetch(`${BASE_URL}/drainage/materials/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          preferred_language: this.$i18n.locale,
          channel_length_m: this.selectedRun.channel_length_m,
          profile: {
            catchmentLength_mm: this.selectedRun.results.catchmentLength_mm,
            segments: this.selectedRun.results.segments,
          },
          accessories: this.selectedRun.calculationAccessories(this.$store.getters.runs),
          throat: this.selectedRun.results.throat || 0,
          loading: this.selectedRun.loading,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        Object.assign(this.selectedRun.results, data);
      }
    },
    drawInflow(selectable, left, top, isStatic, id = uuidv4(), complete = true) {
      const circle = new fabric.Circle({
        radius: 5,
        fill: this.selectedRun.results.overflowed ? 'blue' : 'red',
        hasBorder: false,
        borderColor: 'transparent',
        hasControls: false,
        left: left + 5,
        top: top,
        selectable: selectable,
        hoverCursor: "pointer",
        isStatic: isStatic,
        id: id,
        class: 'inflow', // need to make it clickable when drawn inside group
        zIndex: 999,
        skewY: this.slopeSkew,
        distance_m: to1DP(((this.leftPadding + this.profileWidth_px) - (left)) / this.xScale),
      })
      if (!complete) {
        circle.stroke = circle.fill;
        circle.strokeWidth = 2;
        circle.fill = "transparent";
      }
      this.canvas.add(circle)
      //console.debug("Drawing inflow circle at ", circle.left, circle.top);
      circle.on('mousedown', () => {
        this.currentInflowId = circle.id
        this.displayAccessoryPopup = false
        this.displayInflowPopup = false;
      })
      // Check whether is a click or a drag & drop
      circle.on('mouseup', (event) => {
        // Make sure that the red circle (inflow) has been dropped on the profile, otherwise don't do anything
        const position = Math.round(event.target.left) + 5;
        if (position >= this.leftPadding && position <= this.leftPadding + this.profileWidth_px) {
          if (this.isSliding) {
            // DRAG AND DROP
            if (circle.isStatic) {
              // Add inflow on canvas with its label and in store
              const distance_m = to1DP((this.leftPadding + this.profileWidth_px - position) / this.xScale);
              this.$store.commit('addInflow', {
                id: id,
                distance_m: distance_m,
                toBeConfirmed: true,
              })

              this.canvas.remove(circle); //Re-create as the ID has been used now
              this.drawInflow(true, 20, this.paddingTop_px, true)
            }

            this.refreshInflows();
            this.isSliding = false
          } else if (event.button === 3 && !circle.isStatic) {
            // Remove inflow/accessory on right click
            this.removeInflow(circle.id);
            return;
          } else {
            // CLICK
            console.assert(!circle.isStatic);
          }

          this.popupPosition = {
            // moved 160 to the left to avoid falling off the screen
            x: event.e.pageX - 160, y: event.e.pageY
          };

          // Open the popup
          this.displayInflowPopup = true
        } else {
          this.currentInflowId = null;

          // If dropped anywhere else, remove the one just dropped and redraw the default one
          if (!isStatic) {
            this.removeInflow(circle.id); //Dragging out an existing inflow
          } else {
            this.canvas.remove(circle)
            this.drawInflow(true, 20, this.paddingTop_px, true,)
          }
        }
      })
      circle.on('moving', () => {
        this.isSliding = true
        if (!isStatic) {
          this.displayInflowPopup = false

          const position = to1DP((this.leftPadding + this.profileWidth_px - circle.left - 5) / this.xScale);
          this.canvas.forEachObject(o => {
            if (o.id === (id + '-label')) {
              o.set('left', circle.left + 5);
              o.set('text', `${this.isDischargeAtZero ? position : to1DP(this.selectedRun.channelLength - position)} m`);
            }
          })
          const payload = {
            id: id,
            distance_m: position,
          }
          // Update distance value stored in Vuex
          this.$store.commit('updateInflow', payload)
        }
      })
      return circle
    },
    drawChannelDetails() {
      const selectedRun = this.$store.getters.selectedRun
      const options = {
        fontSize: this.drawShort ? 10 : 12,
        fontFamily: 'interstate-light',
        width: this.profileWidth_px,
        top: this.drawShort ? this.canvasHeight_px : this.canvasHeight_px - (25 * (10 - this.slopeSkew) / 10),
        originY: 'bottom',
        doNotSkew: true,
        selectable: false,
        left: this.leftPadding,
        objectCaching: false,
      }
      let text = ''
      if (selectedRun.results.largest_channel.includes('HICAP')) {
        // HICAP text formatting
        text = `${selectedRun.results.largest_channel.substring(0, 15)}: `
        text += Object.entries(selectedRun.results.channel_materials).map(([type, channel]) => {
          type = type.substring(16/* = 'RECYFIX HICAP F '.length */);
          const meters = (channel.count * channel.length_mm) / 1000;
          return `${type} x ${meters}m`;
        }).join(', ');
      } else if (selectedRun.selectedSystem.product_type === 'M') {
        //Manual channels don't have a channel length, so it gets NaN metres using the normal logic
        text = `${selectedRun.results.largest_channel} x ${selectedRun.channel_length_m} m`;
      } else {
        text = `${selectedRun.results.largest_channel.substring(0, selectedRun.results.largest_channel.indexOf(','))}: `
        text += Object.entries(selectedRun.results.channel_materials).map(([type, channel]) => {
          type = type.substring(type.indexOf(',') + 2); //Index is immediately before the comma
          const meters = (channel.count * channel.length_mm) / 1000;
          return `${type} x ${meters}m`;
        }).join(', ');
      }
      this.canvas.add(new fabric.Textbox(text, options));
    }
    ,
    drawAccessoryLabels() {
      this.canvas.add(new fabric.Text(this.$t('pointInflow'), {
        fill: 'black',
        fontSize: 12,
        fontFamily: 'interstate-light',
        top: this.paddingTop_px - 10,
        selectable: false,
        left: 45,
        doNotSkew: true,
      }))
      if (this.selectedRun.selectedSystem?.product_type !== 'M') {
        this.canvas.add(new fabric.Text(this.$t('accessory'), {
          fill: 'black',
          fontSize: 12,
          fontFamily: 'interstate-light',
          top: this.paddingTop_px + 50,
          selectable: false,
          left: 45,
          doNotSkew: true,
        }))
      }
    },
    drawAccessory(fill, selectable, left, top, isStatic, id = uuidv4(), toBeConfirmed) {
      const coords = [
        {x: 0, y: 0},
        {x: 10, y: 0},
        {x: 10, y: 15},
        {x: 15, y: 15},
        {x: 5, y: 25},
        {x: -5, y: 15},
        {x: 0, y: 15},
        {x: 0, y: 0},
      ]
      const arrow = new fabric.Polygon(coords, {
            fill: fill,
            hasBorder: false,
            borderColor: 'transparent',
            hasControls: false,
            left: left,
            top: top,
            selectable: selectable,
            hoverCursor: "pointer",
            isStatic: isStatic,
            id: id,
            class: 'accessory',
            doNotSkew: true,
            toBeConfirmed: toBeConfirmed
          }
      )
      this.canvas.add(arrow)
      if (this.hasJustCalculated) {
        // Render measure labels only if not related to inflow
        if (!this.inflowsWithAccessBox.some(iwa => iwa.accessoryId === id) && !arrow.isStatic) {
          const position = to1DP((this.leftPadding + this.profileWidth_px - arrow.left - 10) / this.xScale);
          this.canvas.add(new fabric.Text(`${this.isDischargeAtZero ? position : to1DP(this.selectedRun.channelLength - position)} m`, {
            fontSize: 12,
            fontFamily: 'interstate-light',
            top: this.paddingTop_px - 45,
            left: arrow.left + 10,
            originX: 'center',
            id: `${id}-label`,
            doNotSkew: true,
            selectable: false,
          }));
        }
      }

      // When clicked, generate an arrow on the profile start
      arrow.on('mousedown', () => {
        this.isSliding = false
        this.displayInflowPopup = false;
        this.displayAccessoryPopup = false
        this.currentAccessoryId = arrow.id
      })
      arrow.on('mouseup', (event) => {
        // Make sure that the accessory has been dropped on the profile, otherwise don't do anything
        const position = Math.round(event.target.left) + 10; //Sometimes the end of the channel can be 129.9999999999999
        if (position >= this.leftPadding && position <= this.leftPadding + this.profileWidth_px) {
          if (this.isSliding) {
            // DRAG AND DROP
            if (isStatic) {
              // Add accessory to canvas with its label and in the store
              const distance_m = to1DP((this.leftPadding + this.profileWidth_px - position) / this.xScale);
              this.$store.commit('addAccessory', {
                id: id,
                distance_m: distance_m,
                toBeConfirmed: true,
              })

              this.canvas.remove(arrow); //Re-create as the ID has been used now
              this.drawAccessory('#1c80cf', true, 20, this.paddingTop_px + 50, true, undefined, false);
            }

            this.refreshAccessories();
            this.isSliding = false
          } else if (!selectable) {
            return; //We're not selectable
          } else if (event.button === 3 && !isStatic) {
            // Remove accessory on right click
            this.removeAccessory(id);
            return;
          } else {
            // CLICK
            console.assert(!isStatic);
          }

          // positioned 160 to the left to avoid falling off the screen
          this.popupPosition = {
            x: event.e.pageX - 160, y: event.e.pageY
          };
          // Open the popup
          this.displayAccessoryPopup = true
        } else {
          this.currentAccessoryId = null;

          // If dropped anywhere else, remove the one just dropped and redraw the default one
          if (!isStatic) {
            this.removeAccessory(id); //Dragging out an existing accessory
          } else {
            this.canvas.remove(arrow)
            this.drawAccessory('#1c80cf', true, 20, this.paddingTop_px + 50, true, undefined, false);
          }
        }
      })
      arrow.on('moving', () => {
        this.isSliding = true
        if (!isStatic) {
          arrow.top = this.paddingTop_px - 25
          arrow.left = Math.clamp(arrow.left, this.leftPadding - 10, this.leftPadding + this.profileWidth_px - 10);
        }
        const position = to1DP((this.leftPadding + this.profileWidth_px - arrow.left - 10) / this.xScale);
        const visualPosition = `${this.isDischargeAtZero ? position : to1DP(this.selectedRun.channelLength - position)} m`;
        const allObjects = this.canvas.getObjects()
        for (const o of allObjects) {
          if (o.id === (id + '-label')) {
            o.set('left', arrow.left + 10);
            o.set('text', visualPosition);
          }
        }
        // If the accessory is related to an inflow, update the inflow position as well as the label position and text displaying
        for (const inflow of this.inflowsWithAccessBox) {
          if (inflow.accessoryId === arrow.id) {
            // the accessory is related to an inflow
            const relatedInflow = allObjects.find(innerO => innerO.id === inflow.id)
            relatedInflow.left = arrow.left + 5
            relatedInflow.setCoords()
            const label = allObjects.find(innerO => innerO.id === inflow.id + '-label')
            label.left = arrow.left + 5
            label.text = visualPosition;
            // Update the stored accessory distance
            const payload = {
              id: inflow.id,
              distance_m: position,
            }
            this.$store.commit('updateInflow', payload)
          }
        }
        if (!isStatic) {
          const payload = {
            id: id,
            distance_m: position
          }
          // Update distance value stored in Vuex
          this.$store.commit('updateAccessory', payload)
        }
      })
      return id
    }
    ,
    deleteAllObjects() {
      this.canvas.remove(...this.canvas.getObjects());
    }
    ,
    drawProfile() {
      this.drawWaterFlow();
      this.canvas.add(this.drawProfileBorders())
      this.canvas.add(this.drawDistanceLabel())
      this.canvas.add(this.drawDepthLabel())

      // If sloped add bottom horizontal line
      if (this.slopeSkew !== 0) {
        const horizontalLine = new fabric.Line([
          this.leftPadding,
          this.profileHeight_px + this.paddingTop_px + (Math.sin(this.slopeSkew * Math.PI / 180) * this.profileWidth_px),
          this.leftPadding + this.profileWidth_px,
          this.profileHeight_px + this.paddingTop_px + (Math.sin(this.slopeSkew * Math.PI / 180) * this.profileWidth_px),
        ], {
          strokeDashArray: [15, 35],
          stroke: 'gray',
          strokeWidth: 1,
          selectable: false
        })
        this.canvas.add(horizontalLine)
      }
      this.drawMarkers()
      if (this.$store.state.user.country.iso_code === 'RD') this.drawProfileDownloadButton();
    },
    refreshInflows() {
      this.canvas.remove(...this.canvas.getObjects().filter(o => o.class === 'inflow' && !o.isStatic));
      if (this.inflows.length < 1) return; //Nothing new to draw

      //This group allows skewing all the point inflows to match the channel body
      const group = [this.drawProfileBorders({
        stroke: 'transparent', //The normal back border is drawn in drawProfile
        skewY: 0, //Applied on the group, will double skew otherwise
        class: 'inflow',
        isStatic: false,
      })];

      // Redraw any previously added inflow based on run configuration
      const dischargeZero = this.isDischargeAtZero;
      for (const inflow of this.inflows) {
        //Aiming for the drawing the inflow circle at the vertical midpoint of the type of channel being used
        //The inflow circle is draw from the top not the centre, so remember to take the radius (5px) from the height
        const distanceFromTop_px = this.paddingTop_px + (this.selectedRun.stepped ? this.inflowHeights.find(ih => {
          return ih.range.start <= inflow.distance_m && ih.range.end > inflow.distance_m
        }).snap_at_px : this.profileHeight_px / 2) - 5;
        const distanceFromLeft_px = this.leftPadding + this.profileWidth_px - this.mToPxHorizontal(inflow.distance_m) - 10;
        group.push(this.drawInflow(true, distanceFromLeft_px,
            distanceFromTop_px, false, inflow.id, !inflow.toBeConfirmed));
        this.canvas.add(new fabric.Text(`${dischargeZero ? inflow.distance_m : to1DP(this.selectedRun.channelLength - inflow.distance_m)} m`, {
          fontSize: 12,
          fontFamily: 'interstate-light',
          top: this.paddingTop_px - 45,
          left: distanceFromLeft_px,
          originX: 'center',
          id: `${inflow.id}-label`,
          selectable: false,
          class: 'inflow',
          isStatic: false,
        }));
      }

      this.canvas.add(new fabric.Group(group, {
        top: this.paddingTop_px,
        left: group.reduce((closest, shape) => {
          //Ensure any inflows right at the left edge don't push everything over
          return Math.min(shape.left, closest);
        }, this.leftPadding),
        skewY: this.slopeSkew,
        selectable: false,
        subTargetCheck: true,
        class: 'inflow',
        isStatic: false,
      }));
    },
    drawDistanceLabel() {
      let options
      if (this.drawShort) {
        let top = this.profileHeight_px + this.paddingTop_px + (this.slopeSkew * 7) + 10
        options = {
          top: top,
          left: (this.profileWidth_px / 2) + 100,
          fontSize: 12,
          fontFamily: 'interstate-light',
          selectable: false,
          doNotSkew: true,
        }
      } else {
        let top = this.profileHeight_px + this.paddingTop_px + 15 + (this.slopeSkew * 15)
        options = {
          top: top,
          left: (this.profileWidth_px / 2) - 40 + this.leftPadding,
          fontSize: 10,
          fontFamily: 'interstate-light',
          selectable: false,
          doNotSkew: true,
        }
      }
      const distanceLabel = new fabric.Text(this.$t('distanceFromDischarge'), options)
      distanceLabel.width = 400
      return distanceLabel
    }
    ,
    drawDepthLabel() {
      const depthLabel = new fabric.Text(this.$t('depth'),
          {
            top: this.profileHeight_px / 2 + this.paddingTop_px,
            left: this.leftPadding - 20,
            originX: 'center',
            fontSize: 12,
            fontFamily: 'interstate-light',
            angle: 270,
            selectable: false,
            doNotSkew: true,
          },
      )
      return depthLabel
    }
    ,
    drawWaterFlow() {
      /**
       * Extrapolate the changes in the given slices' water depths
       * @param {object[]} slices The (profile) slices to extrapolate from
       * @return {number} The "next" water depth in mm
       */
      function estimateFinalDepth(slices) {
        let weightedTotal = 0;
        let totalChange = 0;

        for (let i = slices.length - 1; i > 0; i--) {
          totalChange += ((slices[i]['depth_mm'] - slices[i - 1]['depth_mm']) / 2) * i;
          weightedTotal += i;
        }

        return slices.at(-1)['depth_mm'] + (totalChange / (weightedTotal || 1));
      }

      // Draws the water flow based on the segments data
      let coords = []
      let lastGap_mm = 0;
      let currentGap_mm = 0
      let stepsCoords = []
      let prevDistanceRange_m = 0
      this.inflowHeights.length = 0; //Clear any existing inflow height ranges

      // DEBUG FOR TESTING ONLY
      // const dist_of_interest = [0.2, 1.0, 2.0, 3.0, 4.0,5.0, 6.0, 6.7, 7.2] // DELETE ME

      for (const segment of this.segments) {
        const slopePerSlice_mm = segment['floorChange'] || 0;

        if (this.selectedRun.stepped && (segment['stepRaise'] > 0 || currentGap_mm !== lastGap_mm || slopePerSlice_mm)) {
          const distanceFromDischarge_m = segment['slices'][0]['distance_m'];
          const distanceFromDischarge_px = this.profileWidth_px - this.mToPxHorizontal(distanceFromDischarge_m);
          stepsCoords.push({
            x: distanceFromDischarge_px,
            y: this.profileHeight_px - this.mmToPxVertical(currentGap_mm)
          })
          this.inflowHeights.push({
            snap_at_px: (this.profileHeight_px - this.mmToPxVertical((lastGap_mm + currentGap_mm) / 2)) / 2,
            range: {start: prevDistanceRange_m, end: distanceFromDischarge_m}
          });
          if (segment['stepRaise'] > 0) {
            currentGap_mm += segment['stepRaise'];
            lastGap_mm = currentGap_mm;
            stepsCoords.push({
              x: distanceFromDischarge_px,
              y: this.profileHeight_px - this.mmToPxVertical(currentGap_mm)
            })
          }
          prevDistanceRange_m = distanceFromDischarge_m;
        }

        for (const slice of segment['slices']) {

          // DEBUG FOR TESTING ONLY
          // if (dist_of_interest.includes(slice['distance_m'])) {
          //   console.log("DISTANCE, depth",  slice['distance_m'] , (slice['depth_mm']/10).toFixed(2.0) )
          // }
          // END OF DEBUG

          const distanceFromDischarge_px = this.profileWidth_px - this.mToPxHorizontal(slice['distance_m'])
          const depthFromTop_px = this.profileHeight_px - this.mmToPxVertical(slice['depth_mm'] + currentGap_mm)
          coords.push({x: distanceFromDischarge_px, y: depthFromTop_px})
          currentGap_mm += slopePerSlice_mm;
        }
      }


      // Close the polygon
      const finalSliceHeight_mm = this.segments.length > 0 && estimateFinalDepth(this.segments.at(-1)['slices']);
      coords.push({x: 0, y: this.profileHeight_px - this.mmToPxVertical(currentGap_mm + finalSliceHeight_mm)})
      coords.push({x: 0, y: this.profileHeight_px})
      coords.push({x: this.profileWidth_px, y: this.profileHeight_px})


      // Only for stepped channels profile
      if (this.selectedRun.stepped) {
        // Add the last channel snapping height in pixels
        this.inflowHeights.push({
          snap_at_px: (this.profileHeight_px - this.mmToPxVertical(currentGap_mm)) / 2,
          range: {start: prevDistanceRange_m, end: this.selectedRun.channel_length_m}
        });

        // Close the step polygon, finishing at whatever the last step height was
        stepsCoords.push({x: 0, y: this.profileHeight_px - this.mmToPxVertical(currentGap_mm)});
        stepsCoords.push({x: 0, y: this.profileHeight_px})
        stepsCoords.push({x: this.profileWidth_px, y: this.profileHeight_px})
      }


      const waterFlow = new fabric.Polyline(coords, {
        fill: this.selectedRun.results.overflowed ? '#c31924' : '#5ea6e8', // '#5ea6e8',  //'#00F700'  # lime green
        selectable: false,
        left: this.leftPadding,
        id: 'water flow',
        skewY: this.slopeSkew,
        top: this.profileHeight_px + this.paddingTop_px + (Math.sin(this.slopeSkew * Math.PI / 180) * this.profileWidth_px),
        originY: 'bottom',
      })

      // return waterFlow
      this.canvas.add(waterFlow)

      // Only for stepped channels profile
      if (this.selectedRun.stepped) {
        // Overlay the steps polygon
        const steps = new fabric.Polyline(stepsCoords.reverse(), {
          fill: 'white',
          selectable: false,
          left: this.leftPadding,
          id: 'steps',
          skewY: this.slopeSkew,
          stroke: 'black',
          top: this.profileHeight_px + this.paddingTop_px + (Math.sin(this.slopeSkew * Math.PI / 180) * this.profileWidth_px),
          originY: 'bottom',
        })
        this.canvas.add(steps)
      }

      if (this.selectedRun.dischargeSetting === "outflow-in-trashbox" && this.selectedRun.outflowTrashbox) {
        const exitDepth_px = this.mmToPxVertical(this.segments[0].slices[0].depth_mm);
        this.canvas.add(new fabric.Polyline([
            {x: 0, y: 0}, {x: 0, y: exitDepth_px + trashboxDepth},
          {x: trashboxWidth, y: exitDepth_px + trashboxDepth}, {x: trashboxWidth, y: 0},
        ], {
          left: this.leftPadding + this.profileWidth_px,
          top: this.profileHeight_px + this.paddingTop_px + (Math.sin(this.slopeSkew * Math.PI / 180) * this.profileWidth_px) + trashboxDepth,
          originY: 'bottom',
          skewY: slopeTrashboxes ? this.slopeSkew : 0,
          fill: this.selectedRun.results.overflowed ? '#c31924' : '#5ea6e8',
          selectable: false,
        }));
      }

      const maxPath = this.selectedRun.results['total_system_volume_path'];
      if (maxPath) {
        const invertOffset = this.mmToPxVertical(this.selectedRun.results.max_channel_depth_mm - maxPath.segments.flatMap(
            segment => segment['slices'].map(slice => slice['depth_mm'])
        ).reduce((a, b) => Math.max(a, b)));
        console.assert(invertOffset >= 0);
        const volumeCoords = [];
        let currentGap_mm = 0;

        for (const [i, segment] of maxPath.segments.entries()) {
          currentGap_mm += this.segments[i]['stepRaise'] ?? 0;
          const slopePerSlice_mm = this.segments[i]['floorChange'] || 0;
          for (const slice of segment['slices']) {
            const distanceFromDischarge_px = this.profileWidth_px - this.mToPxHorizontal(slice['distance_m']);
            const depthFromTop_px = this.profileHeight_px - this.mmToPxVertical(slice['depth_mm'] + currentGap_mm);
            volumeCoords.push({x: distanceFromDischarge_px, y: depthFromTop_px});
            currentGap_mm += slopePerSlice_mm;
          }
        }

        const finalSliceHeight_mm = estimateFinalDepth(maxPath.segments.at(-1)['slices']);
        volumeCoords.push(
            {x: 0, y: this.profileHeight_px - this.mmToPxVertical(currentGap_mm + finalSliceHeight_mm)},
            {x: 0, y: this.profileHeight_px},
            {x: this.profileWidth_px, y: this.profileHeight_px},
        );

        this.canvas.add(new fabric.Polyline(volumeCoords, {
          stroke: this.selectedRun.results.overflowed ? 'green' : 'red',
          fill: 'transparent',
          left: this.leftPadding,
          skewY: this.slopeSkew,
          top: waterFlow.top,
          originY: waterFlow.originY,
          selectable: false,
        }));
      }
    },
    drawProfileDownloadButton() {
      fabric.loadSVGFromURL(saveIcon, objects => {
        if (!objects?.length) {//If the SVG fails to load... draw something else
          objects = [
            new fabric.Text('↓', {
              fill: 'white',
              fontSize: 20,
            }),
          ];
        }
        const label = new fabric.Group(objects, {
          width: 16,
          height: 16,
          left: 8,
          right: 8,
          top: 8,
          bottom: 8,
        });
        const button = new fabric.Rect({
          fill: '#01595A',
          width: 32,
          height: 32,
          rx: 4,
          ry: 4,
        });

        const container = new fabric.Group([button, label], {
          left: this.profileWidth_px + this.leftPadding + 8 + 4, //Extra 4 pixels to keep clear of discharge accessories
          top: this.paddingTop_px - 32 - 8,
          hoverCursor: 'pointer',
          selectable: false,
          isStatic: true,
        });
        container.on('mouseover', () => {
          button.set('fill', '#759C9C');
          this.canvas.renderAll();
        });
        container.on('mouseout', () => {
          button.set('fill', '#01595A');
          this.canvas.renderAll();
        });
        container.on('mousedown', async (event) => {
          if (event.button !== 1) return; //We only want to download on left clicks
          const dischargeZero = this.isDischargeAtZero;
          const csv = this.segments.flatMap(segment => segment.slices.map(slice =>
              `${dischargeZero ? slice.distance_m : this.selectedRun.channelLength - slice.distance_m}, ${slice.flow_l_s}, ${slice.depth_mm}\n`
          ));
          if (!dischargeZero) csv.reverse(); //Count the distance up not down
          csv.unshift('Distance (m), Flow (l/s), Water Depth (mm)\n');
          const url = URL.createObjectURL(new Blob(csv, {type: 'text/csv'}));
          try {
            const a = document.createElement('a');
            a.href = url;
            a.download = `${this.selectedRun.name}.csv`;
            a.click();
          } finally {//Make sure to clean up the URL again
            URL.revokeObjectURL(url);
          }
        });
        this.canvas.add(container);
      }, (index, element) => element.set('fill', 'white'));
    },
    drawProfileBorders(options = {}) {
      const profileBorders = new fabric.Rect({
        left: this.leftPadding,
        top: this.paddingTop_px,
        fill: 'transparent',
        stroke: 'black',
        width: this.profileWidth_px,
        height: this.profileHeight_px,
        skewY: this.slopeSkew,
        selectable: false,
        id: 'profile-borders',
        ...options,
      })
      if (this.selectedRun.dischargeSetting === "outflow-in-trashbox" && this.selectedRun.outflowTrashbox) {
        return new fabric.Group([profileBorders, new fabric.Rect({
          left: this.leftPadding + this.profileWidth_px,
          top: this.paddingTop_px + (Math.sin(this.slopeSkew * Math.PI / 180) * this.profileWidth_px),
          fill: 'transparent',
          stroke: 'black',
          width: trashboxWidth,
          height: this.profileHeight_px + trashboxDepth,
          skewY: slopeTrashboxes ? this.slopeSkew : 0,
          selectable: false,
          ...options,
        })], {
          selectable: false,
        });
      }
      return profileBorders
    }
    ,
    mToPxHorizontal(width_m) {
      return width_m * this.xScale
    }
    ,
    mmToPxVertical(height_mm) {
      return height_mm * this.yScale
    }
    ,
    drawMarkers() {
      let markersGroup = []
      // Draw the grey markers every 'n' meters based on the channel length
      const channelLength_m = this.selectedRun.channel_length_m
      let markersGap_m
      // Render a sensible number of markers (if too many, the labels get cluttered)
      if (channelLength_m < 20) {
        markersGap_m = 2
      } else if (channelLength_m < 50) {
        markersGap_m = 5
      } else if (channelLength_m < 200) {
        markersGap_m = 10
      } else {
        markersGap_m = 20
      }
      const numberOfMarkers = Math.ceil(channelLength_m / markersGap_m) - 1;
      // Add or remove one label based on channel length (e.g, 13m would render
      // 2 labels - 5m and 10m - plus the final - 13m, whereas 17m would render
      // 3 labels - 5m, 10m, and 15m - plus the final - 17m)
      const markersGap_px = markersGap_m * this.profileWidth_px / channelLength_m
      const dischargeZero = this.isDischargeAtZero;
      let left = dischargeZero ? this.profileWidth_px + this.leftPadding : this.leftPadding;
      for (let i = 0; i < numberOfMarkers; i++) {
        dischargeZero ? left -= markersGap_px : left += markersGap_px;
        const marker = new fabric.Line([left, 0, left, this.profileHeight_px], {
          stroke: 'lightgrey',
          selectable: false,
          id: 'marker',
          top: this.paddingTop_px,
        })
        const label = new fabric.Text(`${(i + 1) * markersGap_m}`, {
          top: this.profileHeight_px + this.paddingTop_px,
          left: left,
          originX: 'center',
          fontSize: 12,
          fontFamily: 'interstate-light',
          id: 'marker',
          selectable: false,
        })
        markersGroup.push(marker, label);
      }
      const labelHead = new fabric.Text(`${channelLength_m}`, {
        left: dischargeZero ? this.leftPadding : this.profileWidth_px + this.leftPadding,
        top: this.profileHeight_px + this.paddingTop_px,
        originX: !dischargeZero || channelLength_m % markersGap_m === 0 ? 'center' : 'right',
        //If the gap size divides the channel length, centre the marker as it's an equal distance over to the next one
        fontSize: 12,
        fontFamily: 'interstate-light',
        id: 'marker',
        selectable: false,
      });
      const labelZero = new fabric.Text('0', {
        left: dischargeZero ? this.profileWidth_px + this.leftPadding : this.leftPadding,
        top: this.profileHeight_px + this.paddingTop_px,
        originX: dischargeZero || channelLength_m % markersGap_m === 0 ? 'center' : 'right',
        fontSize: 12,
        fontFamily: 'interstate-light',
        id: 'marker',
        selectable: false
      })
      markersGroup.push(labelHead, labelZero);
      const skewGroup = new fabric.Group(markersGroup, {
        id: 'marker',
        top: this.paddingTop_px,
        selectable: false,
        skewY: this.slopeSkew
      })
      this.canvas.add(skewGroup)

    }
    ,
    deleteMarkers() {
      const allObjects = this.canvas.getObjects()
      allObjects.map(o => o.id === 'marker' && this.canvas.remove(o))
    }
  },
  computed: {
    ...mapGetters([
      'isDischargeAtZero',
    ]),
    profileHeight_px() {
      if (this.drawShort) {
        return 70
      } else {
        return 100
      }
    },
    canvasWidth_px() {
      if (this.drawShort) {
        return 800
      } else {
        return 1200
      }
    },
    profileWidth_px() {
      if (this.drawShort) {
        return 600
      } else {
        return 1000
      }
    },
    inflows() {
      return this.$store.getters.getInflows
    },
    accessories() {
      return this.$store.getters.getAccessories
    },
    inflowsWithAccessBox() {
      return this.selectedRun.inflowsWithAccessBox;
    },
    canvasHeight_px() {
      //Make sure the canvas is tall enough to fit the whole drawing in (plus 5 pixels)
      return Math.max(
          this.drawShort ? 210 : 280,
          this.paddingTop_px + this.profileHeight_px + (Math.tan(this.slopeSkew * Math.PI / 180) * this.profileWidth_px) + trashboxDepth + 5,
      );
    },
    segments() {
      if (this.selectedRun.controlled_discharge_details) {
        const depth_mm = this.selectedRun.controlled_discharge_details.max_depth_mm;
        //Fix all the slices's depths to the controlled discharge's deepest point
        return this.selectedRun.results.segments?.map(segment => ({
          ...segment,
          slices: [{
            distance_m: segment.slices[0].distance_m,
            depth_mm,
          }],
        }));
      }

      return this.selectedRun.results.segments
    },
    slopeSkew() {
      return this.selectedRun.ground_slope;
    },
    xScale() {
      return this.profileWidth_px / this.selectedRun.channelLength
    },
    yScale() {
      return this.profileHeight_px / this.selectedRun.results.max_channel_depth_mm;
    }
  },
  watch: {
    segments() {
      this.hasJustCalculated = true
      if (this.$store.getters.getIsCalculated) {
        this.populateDrawing();
      }
      this.hasJustCalculated = false
    },
    '$i18n.locale'() {
      this.populateDrawing();
    },
  }
}
</script>

<style scoped>
@font-face {
  font-family: "interstate-light";
  src: url("~@/assets/fonts/Interstate-Light.ttf");
}

#canvas {
  /*border: 1px solid red;*/
}

#canvas-wrapper {
  display: flex;
  justify-content: right;
}

::v-deep(.canvas-container) {
  margin: 0 auto;
  /*width: 0;*/
}

p {
  margin: 0;
}

@media (max-width: 1900px) {

  #canvas-wrapper {
  }

  #canvas {
    top: -1rem !important;
  }

  ::v-deep(.upper-canvas) {
    /*display: none !important;*/
    height: 180px;
  }

  ::v-deep(.lower-canvas) {
    width: 600px
  }

  ::v-deep(.canvas-container) {
    margin: 0 auto;
    width: 600px;
  }
}
</style>
