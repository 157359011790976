export default {
  "language": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angleščina"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemščina"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francoščina"])},
    "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madžarščina"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italijanščina"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrvaščina"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poljščina"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenščina"])}
  },
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novi"])},
  "load": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obremenitev"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa projekta - Izberite projekt, ki ga želite naložiti"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokacija"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenca"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustvarjalec"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deli projekt"])},
    "shareText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Če želite omogočiti dostop do tega projekta, kopirajte in prilepite spodnjo povezavo za deljenje s sodelavci ali strankami:\n\n", _interpolate(_named("link")), "\n\nDruge stranke se bodo morale registrirati za dostop do iste različice programske opreme za hidravlični izračun."])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiraj povezavo"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbriši projekt"])},
    "noProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ni shranjenih projektov"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prikaz ", "{", "first", "}", " do ", "{", "last", "}", " projektov ", "{", "totalRecords", "}"])},
    "progressBar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nalaganje 0 projektov iz ", _interpolate(_named("total"))]), _normalize(["Nalaganje 1 serije ", _interpolate(_named("total"))]), _normalize(["Naložen ", _interpolate(_named("count")), " serij iz ", _interpolate(_named("total"))])])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ni uspelo naložiti projekta"])}
  },
  "save": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shrani"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shrani projekt"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delite projekt s HAURATON-om"])},
    "advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Družba HAURATON lahko zagotovi vhodfne podatke/ nasvete pri preverjanju zasnove in/ali pomaga doseči stroškovno najučinkovitejše konfiguracije kanalet z optimiziranjem projekta."])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Če je pri uporabi projekta potreben naš odziv, se obrnite na družbo HAURATON:"])},
    "calculateRuns": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pred shranjevanjem je potrebn preračunati naslednje linije: ", _interpolate(_named("runs")), " "])},
    "needProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt potrebuje ime"])},
    "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seja je potekla, poskusite se prijaviti z drugim zavihkom/oknom in nato ponovno shranite"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ni uspelo shraniti projekta..."])}
  },
  "generic_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Slika prečnega prereza je splošna in se uporablja le za ponazoritev"])},
  "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime projekta"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
  "projectDetailsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis projekta"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
  "calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izračuni"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izračunajte"])},
  "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seja je potekla, poskusite se prijaviti z drugim zavihkom/oknom in nato ponovno izračunajte"])},
  "addRunLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj linijo"])},
  "addRunLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodajanje nove kanalete v trenutno zasnovo"])},
  "addNotesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj opombe"])},
  "addNotesLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodajte opombe k zasnovi kanalete. Opombe bodo vključene v hidravlične podatkovne liste."])},
  "resetLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponastavitev"])},
  "resetLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uporabnik lahko vrne potek kanalete v načrtovanju in okno Design Parameters (Parametri načrtovanja) na privzete nastavitve."])},
  "duplicateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podvoji"])},
  "duplicateLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustvari kopijo trenutne zasnove kanalete."])},
  "catchmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prispevna površina"])},
  "channelsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalete"])},
  "resultsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezultati"])},
  "designRunsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasnova"])},
  "materialsListLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam materialov"])},
  "documentsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenti"])},
  "selectionOfDrainageSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem za odvodnjavanje"])},
  "selectionOfChannelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velikost kanalete"])},
  "confirmedLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrjena dolžina"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolžina"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Višina"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne."])},
  "freeOutflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosti odtok"])},
  "outflowInTrashbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odtok preko peskolova"])},
  "trashboxes": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite peskolov"])},
    "chooseShared": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Izberite peskolov (v skupni rabi s ", _interpolate(_named("shared")), ")"])},
    "withDischarge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Peskolov z ", _interpolate(_named("discharge")), "mm izpusta"])},
    "shareWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["V skupni rabi z ", _interpolate(_named("run"))])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Za ", _interpolate(_named("type")), " kanalete ni na voljo peskolova za ", _interpolate(_named("loading"))])}
  },
  "modificationLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifikacija pri iztočnem elementu"])},
  "noBuildInFall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izključi tipe z vgrajenim padcem"])},
  "definitionOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogled na zadnji element za vrtanje"])},
  "drillingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrtanje na elementu za iztok"])},
  "diameterOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premer vrtanja"])},
  "diameterOfDrillingTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premer luknje, ki jo je treba izvrtati v kanaleto"])},
  "distanceEdgeToDrillingCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razdalja: Oddaljenost od konca kanalete do centra vrtanja"])},
  "bottomAxial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dno Osni"])},
  "leftSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leva stranska stena"])},
  "rightSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desna stranska stena"])},
  "cuttingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezanje na elementu za iztok"])},
  "lengthFromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolžina od konca"])},
  "angleOfCutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kot rezanja"])},
  "designParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri zasnove"])},
  "channelRunLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokacija"])},
  "channelRunLocationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokacija projektirane kanalete/točkovnega požiralnika."])},
  "channelLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolžina kanalete"])},
  "channelLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolžina kanalete. Dolžina kanalete mora biti večkratnik 1.0 m. Ne vključuje dimenzij peskolova na začetku in koncu kanalete."])},
  "catchmentDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globina prispevne površine"])},
  "catchmentDepthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razdalja, ki jo zajema prispevna površina od vsake daljše stranice kanalete."])},
  "catchmentArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prispevna površina"])},
  "catchmentAreaTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupna površina (m²), ki jo mora kanaleta odvodnjavati."])},
  "catchment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepravilna prispevna površina"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prispevna površina"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["\"Enakomerno\" - pravokotno oblikovana prispevna površina z enakim koeficientom odtoka.", _interpolate(_list(0)), "\"Neenakomerno\" - prispevna površina, sestavljena iz potencialno več trapezov z različnimi koeficienti odtoka.", _interpolate(_list(0)), "Glejte poglavje Oblikovanje nepravilnih prispevnih površin v datoteki POMOČ."])},
    "irregular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepravilen"])},
    "uniform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enota"])},
    "editShape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi prispevno površino"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odprto"])}
  },
  "catchmentWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Največja prispevna površina je presežena"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prispevna povrina mora biti manjša od 100.000m², prosimo, zmanjšajte svojo zasnovo za vsaj ", _interpolate(_named("excess")), "m²"])}
  },
  "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracija"])},
  "configurationTT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["\"Kaskadna\" linija knalet z nizom globljih kanalet, ki potekajo proti iztoki.", _interpolate(_list(0)), "\"Enotni\" - Kanalete enake širine in globine, ki so med seboj povezane v linijo kanalet.", _interpolate(_list(0)), "Za dodatne informacije glejte datoteko POMOČ."])},
  "stepped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaskada"])},
  "stepWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opomba:"])},
    "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betonske kanalete ne morejo biti v kaskadni varianti."])},
    "steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalete iz jekla ne morejo biti v kaskadni varianti."])}
  },
  "rainfall": {
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta padavin"])},
    "mapTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite ikono dežne kaplje za zemljevid padavin v Združenem kraljestvu. Z nadaljnjim klikom na zemljevid padavin v Združenem kraljestvu izberite natančne podatke o padavinah za določene lokacije. Vrednosti intenzivnosti padavin lahko vnesete tudi ročno."])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokacija"])},
    "intensity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intenzivnost padavin"])},
    "intensityTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S klikom na besedilo \"mm/uro\" spremenite merjenje padavin v litre/sekundo/hektar (l/(s*ha)) ali \"litre/sekundo/m²\" (l/s/m²)."])},
    "stormDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trajanje padavin"])},
    "r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r5"])},
    "r15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r15"])},
    "r60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r60"])},
    "default": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavitev privzetih padavin"])},
      "changeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ali želite izbrano količino padavin uporabiti kot privzeto za nove preračune?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])}
    }
  },
  "calculationMethod": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda načrtovanja"])},
    "gvf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manning"])},
    "hrw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HRW"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Programska oprema HAURATON DesignSoftware uporabniku omogoča, da izbere med dvema formulama hidravličnega načrtovanja za izračun dimenzije kanalet:", _interpolate(_list(0)), "Manning-Strickler (Manning): modificiran Manning-Strickler (Manning)", _interpolate(_list(0)), "Hydraulics Research Wallingford (HRW).", _interpolate(_list(0)), "Za dodatne informacije glejte datoteko POMOČ."])}
  },
  "slopeSetting": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavitev naklona"])},
    "useSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naklon"])},
    "useElevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadmorska višina"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["\"Naklon\" - Vključite naklon tal kot vrednost v %.", _interpolate(_list(0)), "\"Elevation (Nadmorska višina)\" - Naklon terena, izračunan z uporabo spremembe navpične višine v nivoju terena na začetku in na koncu poteka kanalete."])}
  },
  "longitudinalSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzdolžni naklon"])},
  "longitudinalSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To se nanaša na vzdolžni naklon površine tal, vzdolž katere je položena kanaleta (ne na naklon območja povodja, ki se odvaja v kanaleta). Če so tla ravna, vpišite 0 %."])},
  "longitudinalSlopeWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preseganje največjega kota naklona"])},
    "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Največji dovoljeni naklon, za katerega velja hidravlična teorija, je 1 v ", _interpolate(_named("limit"))])}
  },
  "elevationChange": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprememba nadmorske višine"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Višina tal na koncih linije, ki se lahko skupaj z dolžino linije kanalet uporabi za izračun vzdolžnega naklona tal.", _interpolate(_list(0)), "Z = Vrednost nadmorske višine na začetku linije", _interpolate(_list(0)), "I = Vrednost nadmorske višine na iztoku linije."])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začetek"])},
    "discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iztok"])}
  },
  "elevationWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preseženo največje razmerje spremembe višine"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Največji dovoljeni naklon, za katerega velja hidravlična teorija, je 1:30.", _interpolate(_list(0)), "Zmanjšajte višinsko razliko v projektu ali povečajte njegovo dolžino."])}
  },
  "elevationUphillWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začetek linije je nižji od iztoka"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["DS3.0 je bil zasnovan za kanalete, vgrajene na ravnem terenu ali ob blagih pozitivnih vzdolžnih naklonih.", _interpolate(_list(0)), "Prepričajte se, da sprememba višine ne povzroči negativnega naklona."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamenjava višin"])}
  },
  "superCriticalWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomembne informacije"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS3.0 je bil zasnovan za postopno spreminjanje pretoka v linearnih odvodnih sistemih, vgrajenih na ravnem terenu ali z blagimi vzdolžnimi nakloni."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri kanaletah, ki so nameščene vzdolž strmejših pobočij, mora projektant upoštevati, da lahko pretok vode v sistemu kanalet niha med podkritičnimi in nadkritičnimi pogoji. To lahko povzroči večjo turbulenco v telesu kanalete in morebitne lokalne površinske poplave."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri projektih s strmejšimi vzdolžnimi nakloni bo DS3.0 zagotovil zasnove kanalet z zadostno zmogljivostjo za izračunani pretok, vendar ne bo upošteval hitro spremenljivega pretoka, ki se lahko pojavi v kanaleti."])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V takšnih posebnih primerih svetujemo, da projektanti uporabijo ustrezno metodologijo in formulo za predvidevanje, napovedovanje in prilagajanje pogojem superkritičnega pretoka."])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tega ne pokažite več"])}
  },
  "controlledDischarge": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadzorovan iztok"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadzorovan iztok"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kanal HICAP kanalete se lahko uporablja za zadrževanje in \"dušenje\" iztoka vode.", _interpolate(_list(0)), "Projektant lahko nadzoruje hitrost odvajanja vode iz kanalete.", _interpolate(_list(0)), "Če je pretok neomejen, se prikaže vrednost \"0\".", _interpolate(_list(0)), "Oglejte si \"Nadzorovano praznjenje\" v datoteki POMOČ."])},
    "stepWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasnoa z nadzorovanim iztokom ne more biti kaskadni ali z naklonom."])},
    "free": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosti iztok"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitrost, s katero bo voda zapustila kanaleto, če ne bo omejena, na podlagi intenzivnosti padavin in prispevne površine."])}
    },
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponastavitev"])},
    "resetTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponastavitev prostega iztoka"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izračunajte"])},
    "calculateTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izračunajte rezultat omejitve"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uporabi"])},
    "confirmTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uporabite nadzorovani pretok praznjenja za linijo"])},
    "flooded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nobene kanalete ni bilo mogoče zasnovati tako, da bi ustrezal trenutnim parametrom"])},
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbrana kanaleta"])},
    "criticalDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritično trajanje"])},
    "orificeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premer odprtine"])},
    "criticalRainfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritična količina padavin"])},
    "totalChannelVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupni volumen kanalete"])},
    "minimumFreeBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najmanjša prosta stran kanalete"])}
  },
  "minimumChannelSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najmanjša velikost kanalete"])},
  "minimumChannelSizeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najmanjša velikost kanalete, ki se uporabi za to linijo"])},
  "lengthWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presežena največja priporočena dolžina linije kanalet"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Največja priporočena dolžina linije kanalet je 500 m, zato razmislite o zmanjšanju svoje zasnove za vsaj ", _interpolate(_named("excess")), "m"])}
  },
  "lengthError": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Največja dolžina linije kanlet je presežena"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Največja dolžina linije kanalet  ", _interpolate(_named("method")), " je ", _interpolate(_named("limit")), "m, prosimo, da zmanjšate dolžino linije za vsaj ", _interpolate(_named("excess")), "m"])}
  },
  "divide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razdeli"])},
  "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Združi"])},
  "divide&mergeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za podrobnosti v zvezi z prispevnimi območji Razdeli / Združi glejte datoteko POMOČ (Oblikovanje nepravilnih prispevnih območij)"])},
  "complexCatchment": {
    "areaNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Številka prispevne površine"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolžina"])},
    "startDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začetna globina"])},
    "endDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Končna globina"])},
    "surfaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Površina"])},
    "permeability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koeficient odtoka"])}
  },
  "areaLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolžina površine"])},
  "leftSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Območje levo "])},
  "rightSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Območje desno"])},
  "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posamezna stran"])},
  "designSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifikacija zasnove"])},
  "selectedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbrani sistem"])},
  "channelNominalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominalna širina kanalete"])},
  "channelInternalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notranja širina kanalete"])},
  "channelInternalDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notranja globina kanalete"])},
  "channelRoughness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrapavost"])},
  "channelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta kanalete"])},
  "invertDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invertna globina"])},
  "overallDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celotna globina"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nalaganje"])},
  "flowVelocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitrost pretoka"])},
  "channelDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iztok iz kanalete"])},
  "floodingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekomerna hitrost poplavljanja (približno)"])},
  "dischargeFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pretok na iztoku"])},
  "usedVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uporabljeni volumen"])},
  "totalSystemVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupna prostornina sistema"])},
  "freeboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosti bok"])},
  "installedGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vgrajeni padec"])},
  "installedGradientTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Če je potek kanalete zasnovan s \"kaskadno\" konfiguracijo, bo razlika v višinah vključenih enot kanalete prispevala k naklonu poteka kanalete. V takih primerih bo naklon kanalete večji od vzdolžnega naklona."])},
  "hydraulicProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidravlični profil"])},
  "drainageArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Območje odvodnjavanja"])},
  "systemSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbira sistema"])},
  "switchToAdvanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preklopite na napredni način"])},
  "switchToStandard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preklopite na standardno"])},
  "selectASystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite sistem"])},
  "selectAFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite koeficient odtoka"])},
  "selectAFillTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koeficient odtoka - Ta vrednost se nanaša na prepustnost in strukturo materiala na površini tal ter določa količino vode, ki doseže sistem kanaloet, potem ko steče po prispevni površini."])},
  "permeability": {
    "concretePavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beton"])},
    "resinSurface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Površina iz umente mase"])},
    "bpCement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlakovci - cementni spoji"])},
    "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asfalt"])},
    "bpSand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlakovci - spoji polnjeni s peskom"])},
    "gravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gramoz"])},
    "softLandscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naravni teren"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po meri"])}
  },
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokacija"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenca"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podjetje"])},
  "designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektant"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pošta"])},
  "materials": {
    "han": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Številka artikla"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis proizvoda"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Količina"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupaj"])}
  },
  "documents": {
    "exportLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvozni jezik"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predstavitev"])},
    "downloadIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenesi predstavitev"])},
    "hydraulicAnalysis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hidravlična analiza za ", _interpolate(_named("run"))])},
    "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam materialov"])},
    "downloadMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenesite seznam materialov"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije"])},
    "downloadInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenesite informacije"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vsi dokumenti"])},
    "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenesite vse dokumente"])}
  },
  "endCapWithoutOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaključna stena z iztokom"])},
  "endcaps": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite zaključno steno"])},
    "withOutlet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("material")), " ", _interpolate(_named("diameter")), "mm iztok"])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Za ", _interpolate(_named("channel")), " ni na voljo zaključnih sten z iztokom"])}
  },
  "switchSide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamenjaj stran"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrdi"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekliči"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprejmi"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbriši"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opombe"])},
  "pipeLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolžina cevi"])},
  "pipeLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolžina cevi, ki izstopa iz točkovnega odtoka/peskolova"])},
  "pipeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premer cevi"])},
  "pipeDiameterTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premer cevi, ki izstopa iz točkovnega odtoka/peskolova"])},
  "pipeSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naklon cevi"])},
  "pipeSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kot, pod katerim je cev, ki izhaja iz odtoka, glede na tla."])},
  "extensionHat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaljšek"])},
  "extensionHatTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektant lahko na točkovni odtok doda podaljšek z uporabo gumba za preklapljanje"])},
  "arrowsDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Povlecite/spustite (z levim klikom) rdeči krog, da dodate točkovni dotok na potek kanalete.", _interpolate(_list(0)), "Povlecite / spustite modro puščico za dodajanje dodatka na potek kanalete.", _interpolate(_list(0)), "Z desnim klikom na rdeči krog / modro puščico odstranite iz hidravličnega profila."])},
  "pointInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Točkovni\ndotok"])},
  "accessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatna\noprema"])},
  "distanceFromDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddaljenost od iztoka"])},
  "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globina"])},
  "grating": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rešetka"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite rešetko"])},
    "slot_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimenzije rege"])},
    "open_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odprta površina"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolžina rešetke"])}
  },
  "positionWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozor"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vsaj en dotok/dodatek je bil nameščen zunaj novo določene dolžine kanalete, zato so bili vsi odstranjeni. Po želji jih dodajte nazaj."])}
  },
  "accessorySwitchLoss": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozor"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatna oprema je bila odstranjena zaradi preklopa na napredni način. Po želji jih dodajte nazaj."])}
  },
  "popups": {
    "inflowDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o dotoku"])},
    "accessoryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o dodatkih"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položaj"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vir"])},
    "directPipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diretna cev"])},
    "flowRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pretok"])},
    "connectionMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Način priključitve"])},
    "independent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neodvisno"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "trashBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peskolov"])},
    "accessBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revizijski kos"])},
    "accessCover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revizijski pokrov"])},
    "endEap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaključna stena"])},
    "endEapWithOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaključna stena z iztokom"])},
    "concreteChamber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betonska komora"])},
    "noAccessoriesAvailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opozorilo"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za izbrani položaj ni združljive dodatne opreme."])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za več informacij ali pomoč se obrnite na podjetje HAURATON."])}
    },
    "distanceTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevelika oddaljenost od izpusta"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])},
  "webLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spletne povezave"])},
  "staffAdmin": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazaj"])},
    "fact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dejstvo dneva: ", _interpolate(_named("fact"))])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iskanje"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobriši"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uporabniško ime"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polno ime"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podjetje"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država"])},
    "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok"])},
    "hasAdvancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napredni način"])},
    "isStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osebje"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shrani"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prikaz ", "{", "first", "}", " do ", "{", "last", "}", " uporabnikov ", "{", "totalRecords", "}"])}
  },
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjava"])},
  "waterInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dotok vode"])},
  "waterInflowTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta funkcija omogoča vključitev dodatnega točkovnega dotoka v točkovni požiranik. Dodajte prostornino točkovnega dotoka v litrih na sekundo (l/s)."])},
  "selectChannelFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najprej izberite kanaleto."])},
  "lengthDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbrane kanalete se ne ujemajo z navedeno dolžino."])},
  "userSettings": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavitve"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uporabniške nastavitve"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jezik"])},
    "defaultMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privzet način"])},
    "standardMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardni"])},
    "advancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napredno"])},
    "userRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privzete enote intenzivnosti padavin"])},
    "projectRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektne enote intenzivnosti padavin"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIJAVA"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Če že imate račun na tem spletnem mestu, se prijavite tukaj:"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uporabniško ime"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslo"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijava"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ste pozabili geslo? Za ponastavitev gesla kliknite ", _interpolate(_named("here")), "."])},
    "doNotHaveAccount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nimate računa? Registrirajte se ", _interpolate(_named("here")), "."])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tukaj"])}
  },
  "passwordReset": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PONASTAVITEV GESLA"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite svoj e-poštni naslov spodaj in poslali vam bomo povezavo, ki jo lahko uporabite za ponastavitev gesla."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponastavitev gesla"])},
    "otherwiseLogIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["V nasprotnem primeru se lahko prijavite ", _interpolate(_named("here")), "."])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo geslo:"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrdite geslo:"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošljite potrditveno geslo: potrdite potrditveno geslo."])},
    "invalidLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povezava za ponastavitev gesla je bila neveljavna, morda zato, ker je bila že uporabljena."])},
    "invalidLinkTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Če je ponastavitev gesla še vedno potrebna, zahtevajte novo ponastavitev gesla."])}
  },
  "softwareFeatures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FUNKCIJE PROGRAMSKE OPREME"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Različica UK"])},
    "asia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Različica za Azijo"])},
    "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Različica Evropa/Mednarodna"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POVRATNA INFORMACIJA"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Če želite posredovati povratne informacije o naši programski opremi DesignSoftware, glejte naslednje:"])},
    "europe&beyond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evropa in svet"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Združeno kraljestvo"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STOPITE V STIK Z NAMI ZA SPECIALIZIRANO PODPORO PRI PROJEKTIRANJU"])}
  },
  "freedomAdvertBox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SVOBODA PRI NAČRTOVANJU, BREZPLAČNA UPORABA"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naša nova spletna aplikacija \"DesignSoftware\" strokovnjakom v branži omogoča hitro in preprosto hidravlično analizo, določanje velikosti kanalet, projektiranje in specifikacijo izdelkov za paleto sistemov za površinsko odvodnjavanje HAURATON, medtem ko delajo na svojih namiznih, prenosnih ali tabličnih računalnikih."])},
    "justThreeClicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samo trije kliki do izračuna..."])}
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registracija"])},
  "createAnAccountParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Če to spletno mesto uporabljate prvič, spodaj ustvarite račun:"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime in priimek"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priimek"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država"])},
  "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrditev gesla"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naslov"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesto"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštna številka"])},
  "registrationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registracija uspešno shranjena"])},
  "checkConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preverite svoje e-poštno sporočilo za povezavo za potrditev"])},
  "designReassurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zagotovilo o načrtovanju"])},
  "reassuranceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programsko opremo DesignSoftware je temeljito preizkusilo in metodologijo potrdilo neodvisno svetovalno podjetje za hidravliko. Našo \"interno\" programsko opremo za hidravlično načrtovanje tehnično osebje podjetja HAURATON uspešno uporablja že več kot 30 let s popolno zanesljivostjo."])},
  "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaupno"])},
  "confidentialParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrirani uporabniki (zaščiteni z geslom) imajo dostop do varnega in zanesljivega strežnika za shranjevanje svojih končnih modelov. Modeli ostanejo strogo zaupni, razen če uporabniki označijo možnost \"deliti z družbo HAURATON\". Registrirani uporabniki imajo možnost deliti povezavo do shranjenih modelov, da si jih lahko ogledajo drugi registrirani sodelavci ali stranke. Programska oprema DesignSoftware je bila konfigurirana tako, da je v celoti skladna s predpisi GDPR o zasebnosti podatkov."])},
  "hydraulicDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIDRAVLIČNA ZASNOVA - DODATNI SISTEMI"])},
  "hydraulicDesignParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za hidravlično načrtovanje dodatnih sistemov HAURATON, ki niso vključeni v našo programsko opremo DesignSoftware, se obrnite na podjetje HAURATON za tehnično podporo."])},
  "experienceAndExpertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izkušnje in strokovno znanje"])},
  "experienceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podjetje HAURATON, vodilno v svetu na področju proizvodnje sistemov za odvodnjavanje površin, že več kot petinšestdeset let dobavlja izdelke za odvodnjavanje za velike projekte na mednarodnih trgih. Leta 1956 smo razvili svoj prvi linearni drenažni sistem. Od takrat je blagovna znamka HAURATON postala znana po vsem svetu kot merilo kakovosti, zanesljivosti, trajnosti in storitev."])},
  "help": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POMOČ"])},
    "topicsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEME POMOČI"])},
    "videoTutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video priročnik"])},
    "topic": {
      "about_hauraton_designsoftware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O podjetju HAURATON DesignSoftware"])},
      "design_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda načrtovanja"])},
      "menu_bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcije menijske vrstice"])},
      "managing_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravljanje projektov"])},
      "project_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o projektu"])},
      "design_functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcije načrtovanja"])},
      "design_parameters_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okno s parametri načrtovanja "])},
      "irregular_catchment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblikovanje nepravilnih prispevnih površin"])},
      "rainfall_map_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemljevid in podatki o padavinah (različica za Združeno kraljestvo)"])},
      "point_inflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Točkovni vtoki"])},
      "adding_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodajanje dodatkov"])},
      "access_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostopne točke"])},
      "advanced_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preklop na napredne zasnove"])},
      "design_specification_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okno za specifikacijo oblikovanja"])},
      "system_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistemske informacije"])},
      "recyfix_hicap_slot_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECYFIX HICAP kanaleta z rego"])},
      "system_installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vgradnja sistema"])},
      "design_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpora pri načrtovanju"])},
      "allow_flood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omogočanje poplavljanja"])}
    }
  }
}